import {
  FaTachometerAlt,
  FaShoppingCart,
  FaRecycle,
  FaTruck,
  FaComments,
  FaTools,
  FaSync,
  FaBell,
  FaUser,
  FaFileAlt,
  FaUsers,
  FaFileInvoice,
  FaQuestionCircle,
  FaShoppingBag,
  FaShoppingBasket
} from 'react-icons/fa';

const menu = [
  {
    label: "Dashboard",
    key: "dashboard",
    icon: FaTachometerAlt,
  },
  {
    label: "Direct Orders",
    key: "direct-orders",
    icon: FaShoppingBag,
    iconWrapColor: '#ff4d4f',
    iconColor: '#fff',
  },
  {
    label: "Orders",
    key: "orders",
    iconWrapColor: '#52c41a',
    iconColor: '#000',
    icon: FaShoppingCart,
  },
  // {
  //   label: "Urgent Orders",
  //   key: "orders/urgent",
  //   icon: FaShippingFast,
  // },  
  {
    label: "Recycle Orders",
    key: "recycle-orders",
    iconWrapColor: '#faad14',
    iconColor: '#000',
    icon: FaRecycle,
  },
  {
    label: "Calender",
    key: "timeline",
    icon: FaTruck,
  },
  {
    label: "Chat",
    key: "chat",
    icon: FaComments,
  },
  {
    label: "Invoice",
    key: "invoice",
    icon: FaFileInvoice,
  },
  {
    label: "VAT Invoice",
    key: "vat-invoice",
    icon: FaFileInvoice,
  },
  {
    label: "Deliveries",
    key: "deliveries",
    icon: FaTruck,
  },
  {
    label: "Customers",
    key: "customers",
    icon: FaUsers,
  },
  {
    label: "Bulk Repair",
    key: "bulk-repair",
    icon: FaTools,
  },
  {
    label: "Bulk Recycle",
    key: "bulk-recycle",
    icon: FaSync,
  },
  {
    label: "Notifications",
    key: "notifications",
    icon: FaBell,
  },
  {
    label: "User",
    key: "user",
    icon: FaUser,
  },
  {
    label: "Logs",
    key: "logs",
    icon: FaFileAlt,
  },
  {
    label: "Promo Code",
    key: "promo-code",
    icon: FaShoppingBasket,
  },
  {
    label: "Workforce",
    key: "workforce",
    icon: FaUsers,
  },
  {
    label: "Reason",
    key: "reason",
    icon: FaQuestionCircle,
  },
];

export default menu;
