import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

export default function ExpandedRowRender({ record }) {
    return (
        <Row
            gutter={[24, 0]}
            className='my-3'
        >
            <Col
                span={24}
                className='px-4'
            >
                <p className="m-0">Company Name: {record.company_name}</p>
                <p className="m-0">E-Mail: {record.email}</p>
                <p className="m-0">Phone: {record.phone_number}</p>
                <p className="m-0">Order Note: {record.order_note}</p>
                <p className="m-0">Note: {record.notes}</p>
                <p className="m-0 mt-3">Created At: {record.created_at ? dayjs(record.created_at).format("DD-MM-YYYY hh:mm A") : '-'}</p>
                <p className="m-0">Updated At: {record.updated_at ? dayjs(record.updated_at).format("DD-MM-YYYY hh:mm A") : '-'}</p>
                <p className="m-0">Arrival At: {record.date_of_arrival ? dayjs(record.date_of_arrival).format("DD-MM-YYYY hh:mm A") : '-'}</p>
                <p className="m-0">Est. Delivery: {record.est_delivery_date ? dayjs(record.est_delivery_date).format("DD-MM-YYYY hh:mm A") : '-'}</p>
            </Col>
        </Row>
    )
}
