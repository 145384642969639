import { Card, Col, Tooltip, Button, Row, Table, Typography, Space } from 'antd';
import React, { useState, useMemo, useCallback } from 'react';
import { PrinterOutlined } from '@ant-design/icons';

import styles from "../../Orders.module.scss";

import UpdateOrderItem from './UpdateOrderItem';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Text } = Typography;

export default function OrderItems({ setKey, setActiveKey, activeOrder, isShort, setVisibleDeviceChecker }) {
    const [activeItem, setActiveItem] = useState(null);
    const columns = [
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            render: (text, record) => (
                <>
                    {!isShort &&
                        <Tooltip title="Edit Item">
                            <EditOutlined
                                key={record?.key}
                                style={{ marginRight: 8, cursor: 'pointer' }}
                                onClick={() => handleEditItem(record)} // Function to handle the edit action
                            />
                        </Tooltip>
                    }
                    <Text ellipsis={{ tooltip: text ? text : "-" }}>{text ? text : "-"}</Text>
                </>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => <Text ellipsis={{ tooltip: "£" + record.price }}>{"£" + record.price}</Text>
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (_, record) => <Text ellipsis={{ tooltip: "£" + record.price * record.qty }}>{"£" + record.price * record.qty}</Text>
        },
    ];

    const handleEditItem = async (OrderDetails) => {
        setActiveItem(OrderDetails);
        setVisibleAddOrder(true);
    };

    const [visibleAddOrder, setVisibleAddOrder] = useState(false);

    const expandedRowRender = useCallback((record) => {
        const expandedColumns = [
            {
                title: 'IMEI No',
                key: 'imei',
                dataIndex: "imei",
                render: (text) => <Text ellipsis={{ tooltip: text ? text : "-" }}>{text ? text : "-"}</Text>,
            },
            {
                title: 'Service Required',
                dataIndex: 'fault_note',
                key: 'fault_note',
                render: (text) => <Text ellipsis={{ tooltip: text ? text : "-" }}>{text ? text : "-"}</Text>,
            },
            {
                title: 'Collection Time',
                dataIndex: 'est_collection_time',
                key: 'est_collection_time',
                render: (text) => {
                    const formattedTime = text !== '-' ? dayjs(text).format('YYYY-MM-DD hh:mm A') : "-";
                    return <Text ellipsis={{ tooltip: formattedTime }}>{formattedTime}</Text>;
                },
            },
            {
                title: 'Password',
                dataIndex: 'password',
                key: 'password',
                render: (text) => <Text ellipsis={{ tooltip: text ? text : "-" }}>{text ? text : "-"}</Text>,
            },
            {
                title: 'Note',
                dataIndex: 'note',
                key: 'note',
                render: (text) => <Text ellipsis={{ tooltip: text ? text : "-" }}>{text ? text : "-"}</Text>,
            },
        ];

        return (
            <Table
                columns={expandedColumns}
                dataSource={[record]}
                pagination={false}
                size="small"
                rowKey="key"
            />
        );
    }, []);

    const dataSource = useMemo(() =>
        activeOrder?.items?.map(item => ({
            key: item?.id ?? 'N/A',
            model: item?.model_name ?? '-',
            imei: item?.imei_no ?? '-',
            fault_note: item?.fault_note ?? '-',
            password: item?.password ?? '-',
            price: item?.unit_price ?? 0,
            cost_id: item?.cost_id ?? null,
            qty: item?.quantity ?? 1,
            note: item?.note ?? '-',
            est_collection_time: item?.est_collection_time ?? '-',
        })),
        [activeOrder?.items]
    );

    return (
        <>
            <Card
                title={!isShort && (<Text>Order Details</Text>)}
                extra={!isShort && (
                    <Space>
                        <Tooltip title="Print Label">
                            <Button
                                onClick={() => setVisibleDeviceChecker(true)}
                                icon={<PrinterOutlined />}
                                type='primary'
                            />
                        </Tooltip>

                        <Tooltip title="Add an Order Item">
                            <Button
                                type='primary'
                                ghost
                                onClick={() => {
                                    setVisibleAddOrder(true);
                                    setActiveItem(null);
                                }}
                            >
                                Add an Item
                            </Button>
                        </Tooltip>
                    </Space>
                )}
            >
                <Row gutter={16} className={styles.model_card_row}>
                    <Col span={24}>
                        {activeOrder.items &&
                            <Table
                                size='small'
                                columns={columns}
                                dataSource={dataSource}
                                pagination={false}
                                rowClassName={(record) => (record.strikeThrough ? 'strike-through-row' : '')}
                                expandable={{
                                    expandedRowRender,
                                    rowExpandable: () => true,
                                    defaultExpandedRowKeys: dataSource.length > 0 ? [dataSource[0].key] : [],
                                }}
                                rowKey={(record) => record.key}
                            />
                        }
                    </Col>

                    <Col span={24}>
                        <Space.Compact block
                            direction="vertical"
                            span={24}
                            className='mt-4'
                        >
                            {activeOrder?.est_delivery_date &&
                                <Space className='mt-4'>
                                    <Text strong>Est. Delivery:</Text>
                                    <Text type="secondary">{dayjs(activeOrder?.est_delivery_date).format("DD-MM-YYYY hh:mm A")}</Text>
                                </Space>
                            }

                        </Space.Compact>
                    </Col>
                </Row>
            </Card>

            {visibleAddOrder &&
                <UpdateOrderItem
                    visible={visibleAddOrder}
                    handleCancel={() => setVisibleAddOrder(false)}
                    activeOrderId={activeOrder?.id}
                    activeOrder={activeOrder}
                    setKey={setKey}
                    activeItem={activeItem}
                    setActiveKey={setActiveKey}
                />
            }
        </>
    )
}
