import { ExclamationCircleOutlined, EyeOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import { Button, Col, Form, Input, Modal, Row, Select, message, InputNumber, Drawer, Space, Typography, Switch, Upload, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ProductsApi from '../../../../apis/settings/ProductsApi';
import { SiteOptions } from '../../../../components/layout/util/common';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeProduct, handleReset, productSeries, productService, standardTime, productCategory, roles, setIndex, baseUrl }) {
    const validateMessages = {
        required: '${label} is required!',
    };


    const tradeBaseUrl = SiteOptions[5].value === baseUrl;
    const [editorData, setEditorData] = useState((activeProduct?.content) ?? "");
    const [shortDescription, setShortDescription] = useState((activeProduct?.short_description) ?? "");
    const [extraInformationtorData, setextraInformationtorData] = useState((activeProduct?.extra_information) ?? "");
    const [editor2Data, setEditor2Data] = useState((activeProduct?.recycle_content) ?? "");
    const [isRecycle, setIsRecycle] = useState(activeProduct?.is_recycle === 1);
    const [isColor, setIsColor] = useState(activeProduct?.colors?.length > 0 ? true : false);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);


    const onFinish = async (values) => {
        try {

            const formData = new FormData();

            for (const key in values) {
                if (key === 'image' && values[key]) {
                    formData.append(key, values[key].file);
                } else {
                    formData.append(key, values[key]);
                }
            }
            if (activeProduct?.id) {
                formData.append('id', activeProduct.id);
            }
            if (Array.isArray(values.devices) && values.devices.length > 0) {
                const devicesJson = JSON.stringify(values.devices);
                formData.append('repairServices', devicesJson);
            }
            if (Array.isArray(values.colors) && values.colors.length > 0) {
                const colorsJson = JSON.stringify(values.colors);
                formData.append('repairColors', colorsJson);
            }
            if (Array.isArray(values.addOnServices) && values.addOnServices.length > 0) {
                const addOnServicesJson = JSON.stringify(values.addOnServices);
                formData.append('repairAddOnServices', addOnServicesJson);
            }
            if (Array.isArray(values.recycles) && values.recycles.length > 0) {
                const recyclesJson = JSON.stringify(values.recycles);
                formData.append('recycles', recyclesJson);
            }
            if (Array.isArray(values.refurbish) && values.refurbish.length > 0) {
                const refurbishJson = JSON.stringify(values.refurbish);
                formData.append('refurbish', refurbishJson);
            }
            formData.append('content', String(editorData));
            formData.append('recycle_content', String(editor2Data));

            try {

                const updateServices = await ProductsApi.createProduct(baseUrl, formData);
                if (updateServices) {
                    message.success('Services is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeProduct.id]);

        try {
            const deleteProduct = await ProductsApi.deleteProduct(baseUrl, formData, activeProduct.id);
            if (deleteProduct) {
                message.success('Product is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeProduct.model}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }

    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    const [isImage, setIsImage] = useState(false);

    const [imageFile, setImageFile] = useState(null);

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleUploadChange = (info) => {
        const { fileList } = info;

        form.setFieldsValue({
            image: fileList[0] || null,
        });
    };
    return (
        <Drawer
            push={false}
            key="notification-details-model"

            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <Text>Product Form</Text>

                    <div className='d-flex'>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            className='mx-2'
                            form='services-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeProduct?.id ? "Update" : "Create"}
                        </Button>
                    </div>
                </Col>
            </Row>}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={1000}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeProduct && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="services-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    product_category: activeProduct?.product_category ?? 1,
                    recycle_option: activeProduct?.recycle_option ?? 1,
                    slug: activeProduct?.slug ?? null,
                    model: activeProduct?.model ?? null,
                    series_id: activeProduct?.series_id ?? 1,
                    description: activeProduct?.description ?? null,
                    content: activeProduct?.content ?? null,
                    alias: activeProduct?.alias ?? null,
                    short_code: activeProduct?.short_code ?? null,
                    is_recycle: activeProduct?.is_recycle ?? null,
                    is_color: activeProduct?.colors?.length ? true : false,
                    repair_condition_type: activeProduct?.repair_condition_type ? parseInt(activeProduct?.repair_condition_type) : 1,
                    devices: (activeProduct?.product_services_with_standard_time ?? [{
                        service: null,
                        amount: 0,
                        stand_time: null
                    }]).map((device) => ({
                        service: device?.service_id,
                        amount: device?.amount,
                        stand_time: device?.standard_time_id,
                    })),

                    colors: (activeProduct?.colors ?? [{
                        name: null,
                        code: null,
                        image: null,
                    }]).map((color) => ({
                        name: color?.name,
                        code: color?.code,
                        image: color?.image_url,
                    })),

                    addOnServices: activeProduct?.add_on_services?.map((device) => ({
                        service: device?.pivot?.service_id,
                        amount: device?.pivot?.amount,
                    })),

                    featured_service_ids: activeProduct?.featured_services?.map((service) => service.id),
                    faulty_condition_price: activeProduct?.device_condition_prices?.find(price => price.device_condition_id === 3)?.price ?? null,
                    good_condition_price: activeProduct?.device_condition_prices?.find(price => price.device_condition_id === 1)?.price ?? null,
                    poor_condition_price: activeProduct?.device_condition_prices?.find(price => price.device_condition_id === 2)?.price ?? null,
                    recycles: (activeProduct?.recycles ?? [{
                        recycle: null,
                        amount: 0,
                    }])?.map((recycles) => ({
                        recycle: recycles?.id,
                        amount: recycles?.pivot?.amount ?? 0,
                    })),
                    refurbish: (activeProduct?.services ?? [{
                        service: null,
                        amount: 0,
                    }])?.map((services) => ({
                        service: services?.id,
                        amount: services?.pivot?.amount ?? 0,
                    })),
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={24}>

                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item name="model" label="Phone Model:" rules={[{ required: true, },]}>
                                    <Input placeholder="Phone Model" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="slug" label="URL:" rules={[{ required: true, },]}>
                                    <Input placeholder="URL" />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="alias" label="Alias (Optional)" >
                                    <Input placeholder="Alias" />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="description" label="Description" >
                                    <Input placeholder="Description" />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="meta_title" label="Meta Title">
                                    <Input placeholder="Meta Title" />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="meta_description" label="Meta Description">
                                    <Input placeholder="Meta Description" />
                                </Form.Item>
                            </Col>
                            {!tradeBaseUrl && (
                                <Col span={8}>

                                    {activeProduct?.image_url && !isImage ? (
                                        <>
                                            <div>
                                                <Text>Image</Text><br />
                                                <Link
                                                    to={{ pathname: activeProduct?.image_url }}
                                                    target="_blank"
                                                    className="btn-sign-in"
                                                >
                                                    view <EyeOutlined />
                                                </Link>
                                                <Button type="primary" className='mx-4' onClick={() => setIsImage(true)}>Change</Button>
                                            </div>
                                        </>
                                    ) : (
                                        <Form.Item name="image" label="Image" rules={[
                                            {
                                                required: !activeProduct?.image_url,
                                                message: 'Please upload an image or ensure the existing image is valid.',
                                            },
                                        ]}>
                                            <Upload
                                                listType="picture"
                                                maxCount={1}
                                                onChange={handleUploadChange}
                                                beforeUpload={() => false}
                                            >
                                                <Button type="primary" icon={<UploadOutlined />}>
                                                    Upload
                                                </Button>
                                            </Upload>
                                        </Form.Item>
                                    )}
                                </Col>
                            )}
                            <Col span={8}>
                                <Form.Item name="product_category" label="Product Category" rules={[{ required: true }]} labelCol={{ span: 24 }}>

                                    <Select
                                        optionLabelProp="children"
                                        placeholder="Select Category"
                                    >
                                        {(productCategory?.data ?? []).map((item, idx) => (
                                            <Option key={idx} value={item.id}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="series_id" label="Product Series" rules={[{ required: true }]} labelCol={{ span: 24 }}>

                                    <Select
                                        optionLabelProp="children"
                                        showSearch
                                        placeholder="Select Series"
                                        filterOption={(input, option) =>
                                            (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {(productSeries?.data ?? []).map((item, idx) => (
                                            <Option key={idx} value={item.id}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {tradeBaseUrl ? (
                                <>
                                    <Col span={24}>
                                        <Space direction="vertical" size="small">
                                            <Text className='text-danger'>Select the enabling services</Text>
                                            <Space>
                                                <Form.Item name="is_refurbish" valuePropName="checked" >
                                                    <Checkbox>
                                                        Is Refurbish
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item name="is_refurbish" valuePropName="checked" >
                                                    <Checkbox>
                                                        Is Refurbish
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item name="is_refurbish" valuePropName="checked" >
                                                    <Checkbox>
                                                        Is Buy Service
                                                    </Checkbox>
                                                </Form.Item>

                                            </Space>
                                        </Space>

                                    </Col>
                                    <Col span={24}>
                                        <p className="mb-2">Refurbish </p>
                                        <Form.List name="refurbish">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Space
                                                            key={key}
                                                            style={{
                                                                display: 'flex',
                                                                marginBottom: 8,
                                                            }}
                                                            align="start"
                                                        >
                                                            {/* Service Selection */}
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'service']}
                                                                label="Service"
                                                                rules={[{ required: true, message: 'Missing Service Name' }]}
                                                            >
                                                                <Select
                                                                    optionLabelProp="children"
                                                                    showSearch
                                                                    style={{ width: 300 }}
                                                                    placeholder="Select Service"
                                                                    filterOption={(input, option) =>
                                                                        (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                >
                                                                    {(productService?.data ?? []).map((item) => (
                                                                        <Select.Option key={item.id} value={item.id}>
                                                                            {item.name}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>

                                                            {/* Amount Field */}
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'amount']}
                                                                label="amount"
                                                                rules={[{ required: true, message: 'Missing amount' }]}
                                                                labelCol={{ span: 24 }}>
                                                                <InputNumber
                                                                    style={{ width: 150 }} size="large" placeholder="Amount" />
                                                            </Form.Item>

                                                            {/* Remove Button */}
                                                            <MinusCircleOutlined className='pt-8' onClick={() => remove(name)} />
                                                        </Space>
                                                    ))}

                                                    {/* Add Field Button */}
                                                    <Form.Item>
                                                        <Button
                                                            size="middle"
                                                            shape="round"
                                                            type="dashed"
                                                            onClick={() => add({ service: '', amount: 0, stand_time: '' })}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add Service
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col span={24}>
                                        <p className="mb-2">Repair Services:</p>
                                        <Form.List name="devices">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Space
                                                            key={key}
                                                            style={{
                                                                display: 'flex',
                                                                marginBottom: 8,
                                                            }}
                                                            align="start"
                                                        >
                                                            {/* Service Selection */}
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'service']}
                                                                label="Service"
                                                                rules={[{ required: true, message: 'Missing Service Name' }]}
                                                            >
                                                                <Select
                                                                    optionLabelProp="children"
                                                                    showSearch
                                                                    style={{ width: 300 }}
                                                                    placeholder="Select Service"
                                                                    filterOption={(input, option) =>
                                                                        (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                >
                                                                    {(productService?.data ?? []).map((item) => (
                                                                        <Select.Option key={item.id} value={item.id}>
                                                                            {item.name}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>

                                                            {/* Amount Field */}
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'amount']}
                                                                label="amount"
                                                                rules={[{ required: true, message: 'Missing amount' }]}
                                                                labelCol={{ span: 24 }}>
                                                                <InputNumber
                                                                    style={{ width: 150 }} size="large" placeholder="Amount" />
                                                            </Form.Item>

                                                            {/* Standard Time Selection */}
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'stand_time']}
                                                                label="Standard Time"
                                                            >
                                                                <Select
                                                                    optionLabelProp="children"
                                                                    style={{ width: 300 }}
                                                                    placeholder="Select Standard Time"
                                                                >
                                                                    {(standardTime?.data ?? []).map((item) => (
                                                                        <Select.Option key={item.id} value={item.id}>
                                                                            {item.title}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>

                                                            {/* Remove Button */}
                                                            <MinusCircleOutlined className='pt-8' onClick={() => remove(name)} />
                                                        </Space>
                                                    ))}

                                                    {/* Add Field Button */}
                                                    <Form.Item>
                                                        <Button
                                                            size="middle"
                                                            shape="round"
                                                            type="dashed"
                                                            onClick={() => add({ service: '', amount: 0, stand_time: '' })}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add Service
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                </>
                            )}


                            {tradeBaseUrl ? (
                                <>
                                    <Col span={24}>
                                        <p className="mb-2">Recycles </p>
                                        <Form.List name="recycles">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Space
                                                            key={key}
                                                            style={{
                                                                display: 'flex',
                                                                marginBottom: 8,
                                                            }}
                                                            align="start"
                                                        >
                                                            {/* Service Selection */}
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'recycle']}
                                                                label="recycle"
                                                                rules={[{ required: true, message: 'Missing Service Name' }]}
                                                            >
                                                                <Select
                                                                    optionLabelProp="children"
                                                                    showSearch
                                                                    style={{ width: 300 }}
                                                                    placeholder="Select Recycles"
                                                                    filterOption={(input, option) =>
                                                                        (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                >
                                                                    {(productService?.data ?? []).map((item) => (
                                                                        <Select.Option key={item.id} value={item.id}>
                                                                            {item.name}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>

                                                            {/* Amount Field */}
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'amount']}
                                                                label="amount"
                                                                rules={[{ required: true, message: 'Missing amount' }]}
                                                                labelCol={{ span: 24 }}>
                                                                <InputNumber
                                                                    style={{ width: 150 }} size="large" placeholder="Amount" />
                                                            </Form.Item>

                                                            {/* Remove Button */}
                                                            <MinusCircleOutlined className='pt-8' onClick={() => remove(name)} />
                                                        </Space>
                                                    ))}

                                                    {/* Add Field Button */}
                                                    <Form.Item>
                                                        <Button
                                                            size="middle"
                                                            shape="round"
                                                            type="dashed"
                                                            onClick={() => add({ service: '', amount: 0, stand_time: '' })}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add Service
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="short_description" label="hort Description (Optional)">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={shortDescription}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setShortDescription(data);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="extra_information" label="Additional Information (Optional)">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={extraInformationtorData}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setextraInformationtorData(data);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>

                                        {activeProduct?.featured_image && !isImage ? (
                                            <>
                                                <div>
                                                    <Text>Upload Featured Image (Optional)</Text><br />
                                                    <Link
                                                        to={{
                                                            pathname: `${baseUrl.replace("/api", "")}${activeProduct?.featured_image}`
                                                        }}
                                                        target="_blank"
                                                        className="btn-sign-in"
                                                    >
                                                        view <EyeOutlined />
                                                    </Link>
                                                    <Button type="primary" className='mx-4' onClick={() => setIsImage(true)}>Change</Button>
                                                </div>
                                            </>
                                        ) : (
                                            <Form.Item name="image" label="Image">
                                                <Upload
                                                    listType="picture"
                                                    maxCount={1}
                                                    onChange={handleUploadChange}
                                                    beforeUpload={() => false}
                                                >
                                                    <Button type="primary" icon={<UploadOutlined />}>
                                                        Upload
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        )}
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="editor3" label="Content (Optional)">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={editorData}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setEditorData(data);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col span={24}>
                                        <Form.Item name="editor1" label="Content">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={editorData}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setEditorData(data);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item name="featured_service_ids" label="Featured Services" labelCol={{ span: 24 }}>
                                            <Select
                                                optionLabelProp="children"
                                                showSearch
                                                style={{ width: 400 }}
                                                mode="multiple"
                                                placeholder="Select Service"
                                                filterOption={(input, option) =>
                                                    (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                                                }
                                            >
                                                {(productService?.data ?? []).map((item) => (
                                                    <Select.Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>


                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <p className="mb-2">Add on Services:</p>
                                        <Form.List name="addOnServices">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Space
                                                            key={key}
                                                            style={{
                                                                display: 'flex',
                                                                marginBottom: 8,
                                                            }}
                                                            align="start"
                                                        >
                                                            {/* Service Selection */}
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'service']}
                                                                label="Service"
                                                                rules={[{ required: true, message: 'Missing Service Name' }]}
                                                            >
                                                                <Select
                                                                    optionLabelProp="children"
                                                                    showSearch
                                                                    style={{ width: 300 }}
                                                                    placeholder="Select Service"
                                                                    filterOption={(input, option) =>
                                                                        (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                >
                                                                    {(productService?.data ?? []).map((item) => (
                                                                        <Select.Option key={item.id} value={item.id}>
                                                                            {item.name}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>

                                                            {/* Amount Field */}
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'amount']}
                                                                label="amount"
                                                                rules={[{ required: true, message: 'Missing amount' }]}
                                                                labelCol={{ span: 24 }}>
                                                                <InputNumber
                                                                    style={{ width: 150 }} size="large" placeholder="Amount" />
                                                            </Form.Item>

                                                            {/* Remove Button */}
                                                            <MinusCircleOutlined className='pt-8' onClick={() => remove(name)} />
                                                        </Space>
                                                    ))}

                                                    {/* Add Field Button */}
                                                    <Form.Item>
                                                        <Button
                                                            size="middle"
                                                            shape="round"
                                                            type="dashed"
                                                            onClick={() => add({ service: '', amount: 0, stand_time: '' })}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add Service
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    <Col span={16}>

                                    </Col>
                                    <Col span={24}>
                                        <Text className='text-danger'>Recycle - Sell My Device</Text>

                                        <Form.Item label={`Is Recycle`} name="is_recycle" labelCol={{ span: 24 }}>
                                            <Switch onChange={(checked) => setIsRecycle(checked)} />
                                        </Form.Item>
                                    </Col>
                                    {isRecycle &&
                                        <>
                                            <Col span={8}>
                                                <Form.Item name="good_condition_price" label="Good Condition Price" labelCol={{ span: 24 }}>
                                                    <Input placeholder="Enter Price" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name="poor_condition_price" label="Poor Condition Price" labelCol={{ span: 24 }}>
                                                    <Input placeholder="Enter Price" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name="faulty_condition_price" label="Faulty Condition Price" labelCol={{ span: 24 }}>
                                                    <Input placeholder="Enter Price" />
                                                </Form.Item>
                                            </Col>

                                            <Col span={24}>
                                                <Form.Item name="editor2" label="Recycle Content">
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={editor2Data}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setEditor2Data(data);
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    }
                                    <Col span={24}>
                                        <Text className='text-danger'>Add Color</Text>
                                        <Form.Item label={`Is Color Required`} name="is_color" labelCol={{ span: 24 }}>
                                            <Switch onChange={(checked) => setIsColor(checked)} />
                                        </Form.Item>
                                    </Col>
                                    {isColor === true &&
                                        <>
                                            <Col span={24}>
                                                <Text className="mb-2">Colors:</Text>
                                                <Form.List name="colors">
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            {fields.map(({ key, name, ...restField }) => (
                                                                <Space
                                                                    key={key}
                                                                    style={{
                                                                        display: 'flex',
                                                                        marginBottom: 2,
                                                                    }}
                                                                    align="start"
                                                                >
                                                                    {/* Color Name */}
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'name']}
                                                                        label="Color Name"
                                                                        rules={[{ required: true, message: 'Missing Color Name' }]}
                                                                        labelCol={{ span: 24 }}
                                                                    >
                                                                        <Input placeholder="Enter color name" />
                                                                    </Form.Item>

                                                                    {/* Color Code */}
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'code']}
                                                                        label="Color Code"
                                                                        rules={[{ required: true, message: 'Missing Color Code' }]}
                                                                        labelCol={{ span: 24 }}
                                                                    >
                                                                        <Input type="color" placeholder="Color Code" />
                                                                    </Form.Item>

                                                                    {/* Upload Image */}
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'image']}
                                                                        label="Upload Image"
                                                                        valuePropName="fileList"
                                                                        getValueFromEvent={({ file }) => file}
                                                                    >
                                                                        <Input
                                                                            type="file"
                                                                            accept="image/*"
                                                                            onChange={(e) => {
                                                                                const file = e.target.files[0];
                                                                                if (file) {
                                                                                    const reader = new FileReader();
                                                                                    reader.onloadend = () => {
                                                                                        form.setFieldsValue({
                                                                                            colors: form.getFieldValue('colors').map((color, index) =>
                                                                                                index === name ? { ...color, image: reader.result } : color
                                                                                            )
                                                                                        });
                                                                                    };
                                                                                    reader.readAsDataURL(file);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Form.Item>

                                                                    {/* Remove Button */}
                                                                    <MinusCircleOutlined
                                                                        className='pt-8'
                                                                        onClick={() => remove(name)}
                                                                    />
                                                                </Space>
                                                            ))}

                                                            {/* Add Field Button */}
                                                            <Form.Item>
                                                                <Button
                                                                    size="middle"
                                                                    shape="round"
                                                                    type="dashed"
                                                                    onClick={() => add({ name: '', code: '', image: null })}
                                                                    icon={<PlusOutlined />}
                                                                >
                                                                    Add Color
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </Col>

                                        </>

                                    }
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}
