import axios from "axios";

import EventBus from '../common/EventBus';
import { API_ENDPOINT1 } from "../constants/varibales";
import userApi from "./auth/userApi";
import { getBaseUrlFromStore } from "../store/util";

export default function axiosInterceptor(baseURL = null) {
    const API_URL = baseURL || API_ENDPOINT1;

    const axiosInstance = axios.create({
        baseURL: API_URL,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        crossDomain: true
    });

    axiosInstance.interceptors.request.use(async config => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    }, error => {
        Promise.reject(error)
    });

    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;
            if (error.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                // Try to refresh the token using the refresh token
                // const newToken = refreshAccessToken();
                // if (!newToken) {
                //     return Promise.reject(error);
                // } else {
                //     axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
                //     return axiosInstance(originalRequest);
                // }

                logoutHandle();
                return Promise.reject(error);
            }

            return Promise.reject(error);
        }
    );

    return axiosInstance;
}

async function refreshAccessToken() {
    try {
        // we can't get the refresh token from the localStorage because that is a personal token, can't be used for refresh.
        // const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
        // if (!refreshToken) {
        //     return null;
        // }

        const baseURL = getBaseUrlFromStore();
        const response = await userApi.refreshToken(baseURL);
        const { token } = response.data;
        localStorage.setItem('token', JSON.stringify(token));
        return token;
    } catch (error) {
        logoutHandle();
        return Promise.reject(error);
    }
}

function logoutHandle() {
    EventBus.dispatch("logout");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("base_url");

    // window.location.href = "/sign-in" ;
}
