import { Button, Col, Table, Drawer, Select, Form, Row, message, Modal, Flex, Typography, Space, Card, Alert, FloatButton, Badge, Popconfirm } from 'antd';
import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { GiCardboardBox } from "react-icons/gi";
import { ExclamationCircleFilled } from '@ant-design/icons';

import OrdersApi from '../../apis/order-management/repair-order/OrdersApi';
import RecycleOrdersApi from '../../apis/order-management/recycle-order/RecycleOrdersApi';
import { getAllOrderStatus as getAllRepairOrderStatus, getOrderStatusByValue as getRepairOrderStatusByValue } from '../../pages/Orders/util/common';
import { getAllOrderStatus as getAllRecycleOrderStatus, getOrderStatusByValue as getRecycleOrderStatusByValue } from '../../pages/RecycleOrders/util/common';
import StatusBadge from '../StatusBadge';
import { clearSiteShippingOrders as clearSiteShippingRepairOrders, deleteSiteShippingOrderWithOrderIds as deleteSiteShippingRepairOrderWithOrderIds } from '../../store/slices/shippingOrdersSlice';
import { clearSiteShippingOrders as clearSiteShippingRecycleOrders, deleteSiteShippingOrderWithOrderIds as deleteSiteShippingRecycleOrderWithOrderIds } from '../../store/slices/shippingOrdersRecycleSlice';
import { SiteOptions } from '../../components/layout/util/common';

const { useForm } = Form;
const { confirm } = Modal;
const { Option } = Select;
const { Text } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

const validateMessages = {
    required: '${label} is required!',
};

const columns = [
    {
        title: 'Order no',
        dataIndex: 'order_no',
        key: 'order_no',
    },
    {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")}</Text> : "-"),
    },
    {
        title: 'Full Name',
        dataIndex: 'first_name',
        key: 'first_name',
    },
    {
        title: 'Postcode',
        dataIndex: 'postal_code',
        key: 'postal_code',
    },
];

const CollectionCreateForm = ({ open, onCancel, orders, setKey, baseUrl, shippingOrders, handleOpenDeviceChecker, ordersType }) => {
    const dispatch = useDispatch();

    const brand = SiteOptions.find(site => site.value === baseUrl).brand;
    const filterIds = shippingOrders?.map(orders => orders.id);
    const filteredOrders = useMemo(() => {
        return orders?.filter(order => filterIds?.includes(order.id));
    }, [orders, shippingOrders, filterIds]);

    const [form] = useForm();

    const [selectedItems, setSelectedItems] = useState([]);

    const deleteShippingOrderWithOrderIds = useCallback((orderIds) => {
        switch (ordersType) {
            case "repair":
                dispatch(deleteSiteShippingRepairOrderWithOrderIds({
                    site: brand,
                    orderId: orderIds
                }));
                break;
            case "recycle":
                dispatch(deleteSiteShippingRecycleOrderWithOrderIds({
                    site: brand,
                    orderId: orderIds
                }));
                break;
            default:
                break;
        }
    }, [ordersType, brand]);

    const clearShippingOrders = useCallback(() => {
        switch (ordersType) {
            case "repair":
                dispatch(clearSiteShippingRepairOrders({ site: brand }));
                break;
            case "recycle":
                dispatch(clearSiteShippingRecycleOrders({ site: brand }));
                break;
            default:
                break;
        }
    }, [ordersType, brand]);

    const handleDelete = () => {
        deleteShippingOrderWithOrderIds(selectedItems);
        setSelectedItems([]);

        if (selectedItems.length === 0) {
            onCancel();
        }
    };

    const clearAll = () => {
        clearShippingOrders();
        onCancel();
    };

    const handleOrderStatusUpdate = async (status) => {
        const loadingMessage = message.loading('Order is updating..', 0);
        try {
            const orderUpdateEndpoints = selectedItems.map(async (orderId) => {
                const payload = {
                    status: status,
                    is_active: 1,
                    order_type: ordersType
                };

                let endpoint = null;
                if (ordersType === "recycle") {
                    endpoint = RecycleOrdersApi.updateOne(baseUrl, orderId, payload);
                } else {
                    endpoint = OrdersApi.updateOne(baseUrl, orderId, payload);
                }
                return endpoint;
            });

            const responses = await Promise.all(orderUpdateEndpoints);
            if (responses) {
                setKey(prevKey => prevKey + 1);

                // Order is updated
                message.success(`Order is updated successfully!`);

                    if (status === 2 || status === 9 && ordersType === "repair" && selectedItems.length === 1) {
                        handleOpenDeviceChecker(selectedItems[0]);
                    }

                    if ((status === 3 || status === 11) && ordersType === "repair") {
                        confirm({
                            title: 'Royal mail shipment label',
                            icon: <ExclamationCircleFilled />,
                            content: `Do you want to generate the label for royal mail shipment?`,
                            okText: 'Yes',
                            okType: 'danger',
                            cancelText: 'No',
                            onOk() {
                                const shipmentLoading = message.loading('Shipment label is generating..', 0);

                            const royalMailEndOrderEndpoints = selectedItems.map((orderId) => {
                                OrdersApi.royalMailOrder(baseUrl, orderId);
                            });
                            Promise.all(royalMailEndOrderEndpoints).then((response) => {
                                if (response) {
                                    message.success('Order was exported to Royal Mail!');
                                }
                            }).catch((error) => {
                                throw error;
                            }).finally(() => {
                                shipmentLoading();
                            });
                        },
                        onCancel() {
                            //
                        },
                    });
                }
            }
        } catch (error) {
            // Handle error
            message.error(error.message || 'Something went wrong!');
        } finally {
            if (loadingMessage) {
                loadingMessage();
            }
        }
    };

    const royalMailUpdateHandler = () => {
        confirm({
            title: 'Royal mail shipment label',
            icon: <ExclamationCircleFilled />,
            content: `Do you want to generate the label for royal mail shipment?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                const endpoints = selectedItems.map((orderId) => {
                    OrdersApi.royalMailOrder(baseUrl, orderId);
                });

                Promise.all(endpoints).then((response) => {
                    console.log("royalMailUpdate", response);
                    if (response) {
                        message.success(`Royal mail shipment label is updated!`);
                        form.resetFields(['status']);
                    }
                });
            },
            onCancel() {
                form.resetFields(['status']);
            },
        });
    };

    const rowSelection = {
        preserveSelectedRowKeys: false,
        onChange: (selectedRowKeys) => {
            setSelectedItems(selectedRowKeys);
        }
    }

    const getOrderStatusByValue = useCallback((id) => {
        switch (ordersType) {
            case "repair":
                return getRepairOrderStatusByValue(id);
            case "recycle":
                return getRecycleOrderStatusByValue(id);
            default:
                return null;
        }
    }, [ordersType]);

    const memoizedColumns = useMemo(() => {
        const mColumns = [...columns];

        if (ordersType === "repair") {
            // Arrived At
            mColumns.splice(2, 0, {
                title: 'Arrived At',
                dataIndex: 'date_of_arrival',
                key: 'date_of_arrival',
                render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")}</Text> : "-"),
            });
        }

        // full_name
        mColumns[2] = {
            ...mColumns[2],
            render: (_, record) => {
                return (
                    <Text>
                        {record?.user?.first_name || record?.first_name} {(record?.user?.last_name || record?.last_name) ? record?.user?.last_name || record.last_name : ''}
                    </Text>
                );
            },
        };

        // postal_code
        mColumns[3] = {
            ...mColumns[3],
            render: (_, record) => {
                return (
                    <Text>
                        {record?.user?.postal_code || record?.postal_code}
                    </Text>
                );
            },
        };

        // Status
        mColumns.push({
            title: "Status",
            key: "status",
            dataIndex: "status",
            render: (value) => {
                const statusObj = getOrderStatusByValue(value);
                return (<StatusBadge value={value} color={statusObj?.status} label={statusObj?.label} />);
            },
        });

        return mColumns;
    }, [ordersType]);

    const memoizedGetAllOrderStatus = useMemo(() => {
        switch (ordersType) {
            case "repair":
                return getAllRepairOrderStatus;
            case "recycle":
                return getAllRecycleOrderStatus;
            default:
                return [];
        }
    }, [ordersType]);

    return (
        <Drawer
            open={open}
            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Flex
                    gap="small"
                    align="center"
                    justify='space-between'
                    className='w-100'
                >
                    <Space>
                        <Badge count={shippingOrders?.length} showZero>
                            <GiCardboardBox className='fs-4 text-primary' />
                        </Badge>
                        Basket
                    </Space>

                    {shippingOrders?.length > 0 &&
                        <Popconfirm
                            title="Are you sure to clear all?"
                            description="This action will remove all the records from the basket."
                            onConfirm={clearAll}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                size='small'
                                danger
                            >
                                Clear All
                            </Button>
                        </Popconfirm>
                    }
                </Flex>
            </Row>}
            placement='right'
            onClose={onCancel}
            width={1000}
            destroyOnClose
        >
            {(selectedItems?.length === 0 && filteredOrders.length > 0) &&
                <Alert message="Select the records to perform the bulk actions" type="warning" showIcon />
            }

            {(selectedItems?.length > 0 && filteredOrders.length > 0) &&
                <Row gutter={24}>
                    <Col
                        span={9}
                        className='mb-4'
                    >
                        <Card styles={{ body: { padding: '.5rem 1rem' } }}>
                            <Form
                                {...layout}
                                form={form}
                                name="nest-messages"
                                validateMessages={validateMessages}
                                initialValues={{ status: 2 }}
                                onFinish={(values) => {
                                    handleOrderStatusUpdate(values.status);
                                }}
                            >
                                <Flex align='center'>
                                    <Form.Item
                                        className='mr-4'
                                        name="status"
                                        label="Status"
                                        rules={[{ required: true }]}
                                    >
                                        <Select style={{ width: '200px' }}>
                                            {memoizedGetAllOrderStatus.slice(1).map((item, idx) => (
                                                <Option key={idx} value={item.value}>{item.label}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Button size='small' type="primary" htmlType="submit">
                                        Update
                                    </Button>
                                </Flex>
                            </Form>
                        </Card>
                    </Col>

                    {ordersType !== "direct" &&
                        <Col
                            span={15}
                            className='mb-4'
                        >
                            <Card>
                                <Space>
                                    <Button
                                        color="default"
                                        variant="outlined"
                                        onClick={royalMailUpdateHandler}
                                    >
                                        Export to Royal Mail
                                    </Button>
                                    <Button
                                        color="danger"
                                        variant="outlined"
                                    >
                                        Export to DPD
                                    </Button>
                                </Space>
                            </Card>
                        </Col>
                    }

                    <Col span={24} className='mb-3'>
                        <Button
                            size='small'
                            color="danger"
                            variant="dashed"
                            onClick={handleDelete}
                            icon={<ExclamationCircleFilled />}
                        >
                            Remove Selected
                        </Button>
                    </Col>
                </Row>
            }

            <Table
                className='mt-2'
                size='small'
                rowKey={(record) => record.id}
                rowSelection={rowSelection}
                dataSource={filteredOrders}
                columns={memoizedColumns}
                pagination={false}
            />
        </Drawer>
    );
};

const Basket = ({ orders, setKey, visible, setVisible, baseUrl, handleOpenDeviceChecker, ordersType }) => {
    const brand = SiteOptions.find(site => site.value === baseUrl).brand;
    const shippingOrders = useSelector((state) => {
        const ordersMap = {
            repair: state.repairOrdersShipping?.shipping,
            recycle: state.recycleOrdersShipping?.shipping,
        };
        return ordersMap[ordersType];
    });

    const openDrawer = () => {
        setVisible(true);
    }

    const cancelDrawer = () => {
        setVisible(false);
    }

    return (
        <>
            <FloatButton
                shape="square"
                type='primary'
                onClick={openDrawer}
                badge={{ count: shippingOrders[brand]?.length, showZero: true }}
                icon={<GiCardboardBox className='fs-5' />}
                tooltip="Basket"
                trigger="hover"
            >
                Basket(s)
            </FloatButton>

            <CollectionCreateForm
                open={visible}
                orders={orders}
                setKey={setKey}
                onCancel={cancelDrawer}
                baseUrl={baseUrl}
                shippingOrders={shippingOrders[brand]}
                handleOpenDeviceChecker={handleOpenDeviceChecker}
                ordersType={ordersType}
            />
        </>
    );
};
export default Basket;