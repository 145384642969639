import { Col, Row, Skeleton, Typography } from "antd";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';

import DashboardApi from "../../apis/DashboardApi";
import { SiteOptions } from "../../components/layout/util/common";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import CountPanel from "./groups/CountPanel";
import RecyleCountPanel from "./groups/RecyleCountPanel";
import SalesTable from "./groups/SalesTable";
import PaymentTable from "./groups/PaymentTable";
import UnpaidTable from "./groups/UnpaidTable";
import SalesStatus from "./groups/SalesStatus";
import EChart from "./components/EChart";
import BulkRepairCountPanel from "./groups/BulkRepairCountPanel";
import BulkSchoolCountPanel from "./groups/BulkSchoolCountPanel";
import DirectOrderCountPanel from "./groups/DirectOrderCountPanel";

const { Title, Paragraph } = Typography;

function Dashboard(props) {
    useDocumentTitle(props.title);
    const { name: userName, roles } = useSelector((state) => state.auth.user[0]);
    const baseUrl = useSelector((state) => state.auth.base_url);

    const [dashboardData, setDashboardData] = useState({
        index: null,
        getSales: null,
        getSalesTable: null,
        // getMonthlyStatus: null,
        getSalesReport: null,
        getPayment: null,
        getUnPaidPayment: null,
        getBulkSales: null,
        isLoading: {
            index: true,
            sales: true,
            salesTable: true,
            // monthlyStatus: true,
            salesReport: true,
            payment: true,
            BulkSales: true,
            unPaidPayment: true,
        },
    });

    const [dateRangeEchart, setDateRangeEchart] = useState([dayjs().subtract(1, 'month'), dayjs()]);
    const [dateRange, setDateRange] = useState([dayjs().subtract(1, 'month'), dayjs()]);

    const handleDateChangeEchart = (dates) => {
        if (dates && dates.length === 2) {
            setDashboardData(prev => ({
                ...prev,
                isLoading: { ...prev.isLoading, sales: true },
            }));

            setDateRangeEchart(dates);
            DashboardApi.getSales(baseUrl, dates[0].format('YYYY/MM/DD'), dates[1].format('YYYY/MM/DD')).then(getSalesData => {
                setDashboardData(prev => ({
                    ...prev,
                    getSales: getSalesData,
                    isLoading: { ...prev.isLoading, sales: false },
                }));
            });
        }
    };

    const handleDateChange = (dates) => {
        if (dates && dates.length === 2) {
            setDashboardData(prev => ({
                ...prev,
                isLoading: { ...prev.isLoading, salesReport: true },
            }));

            setDateRange(dates);
            DashboardApi.getSalesReport(baseUrl, dates[0].format('YYYY/MM/DD'), dates[1].format('YYYY/MM/DD')).then(getSalesReportData => {
                setDashboardData(prev => ({
                    ...prev,
                    getSalesReport: getSalesReportData,
                    isLoading: { ...prev.isLoading, salesReport: false },
                }));
            })
        }
    };

    const fetchData = useCallback(async (endpoints) => {
        try {
            await Promise.all(endpoints);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        } finally {
            setDashboardData(prev => ({
                ...prev,
                isLoading: {
                    index: false,
                    sales: false,
                    salesTable: false,
                    // monthlyStatus: false,
                    salesReport: false,
                    payment: false,
                    unPaidPayment: false,
                },
            }));
        }
    }, []);

    const getDashboardData = useCallback(async () => {
        setDashboardData(prev => ({
            ...prev,
            isLoading: {
                index: true,
                sales: true,
                salesTable: true,
                // monthlyStatus: true,
                salesReport: true,
                payment: true,
                unPaidPayment: true,
            },
        }));

        const endpoints = [
            DashboardApi.index(baseUrl).then(indexData => {
                setDashboardData(prev => ({
                    ...prev,
                    index: indexData,
                    isLoading: { ...prev.isLoading, index: false },
                }));
            }),
            DashboardApi.getSales(baseUrl, dateRangeEchart[0].format('YYYY/MM/DD'), dateRangeEchart[1].format('YYYY/MM/DD')).then(getSalesData => {
                setDashboardData(prev => ({
                    ...prev,
                    getSales: getSalesData,
                    isLoading: { ...prev.isLoading, sales: false },
                }));
            }),
            DashboardApi.getSalesTable(baseUrl).then(getSalesTableData => {
                setDashboardData(prev => ({
                    ...prev,
                    getSalesTable: getSalesTableData,
                    isLoading: { ...prev.isLoading, salesTable: false },
                }));
            }),
            // DashboardApi.getMonthlyStatus(baseUrl).then(getMonthlyStatusData => {
            //     setDashboardData(prev => ({
            //         ...prev,
            //         getMonthlyStatus: getMonthlyStatusData,
            //         isLoading: { ...prev.isLoading, monthlyStatus: false },
            //     }));
            // }),
            DashboardApi.getSalesReport(baseUrl, dateRange[0].format('YYYY/MM/DD'), dateRange[1].format('YYYY/MM/DD')).then(getSalesReportData => {
                setDashboardData(prev => ({
                    ...prev,
                    getSalesReport: getSalesReportData,
                    isLoading: { ...prev.isLoading, salesReport: false },
                }));
            }),
            DashboardApi.getPayment(baseUrl).then(getPaymentData => {
                setDashboardData(prev => ({
                    ...prev,
                    getPayment: getPaymentData,
                    isLoading: { ...prev.isLoading, payment: false },
                }));
            }),
            DashboardApi.getUnPayment(baseUrl).then(getUnPaidPaymentData => {
                setDashboardData(prev => ({
                    ...prev,
                    getUnPaidPayment: getUnPaidPaymentData,
                    isLoading: { ...prev.isLoading, unPaidPayment: false },
                }));
            }),
            DashboardApi.getBulkSales(baseUrl).then(getBulkSalesData => {
                setDashboardData(prev => ({
                    ...prev,
                    getBulkSales: getBulkSalesData,
                    isLoading: { ...prev.isLoading, unPaidPayment: false },
                }));
            }),
        ];

        await fetchData(endpoints);
    }, [baseUrl, fetchData]);

    useEffect(() => {
        getDashboardData();
    }, [getDashboardData]);

    const siteLabel = useMemo(() => SiteOptions.find(option => option.value === baseUrl)?.label, [baseUrl]);

    const hasRole = useCallback((role_name) => roles.some(role => role.name === role_name), [roles]);
    const isAdmin = useMemo(() => hasRole('admin') || hasRole('super-admin'), [hasRole]);
    const recycleBaseUrl = SiteOptions[1]?.label;

    return (
        <div className="layout-content px-4">
            <Row justify='space-between' align='middle' className="pb-3">
                <Col>
                    <Title level={5}>{siteLabel}</Title>
                </Col>
                <Col>
                    {userName && <Title level={5} strong>Welcome {userName},</Title>}
                </Col>
            </Row>

            <Skeleton
                active
                loading={dashboardData.isLoading.index}
                className="my-4"
            >
                {dashboardData.index?.directOrder && (
                    <>
                        <Paragraph strong>Direct Order List</Paragraph>
                        <DirectOrderCountPanel sumOrCountItems={dashboardData.index?.directOrder}
                            isAdmin={hasRole('admin') || hasRole('super-admin')} />
                    </>
                )}
            </Skeleton>

            {siteLabel !== recycleBaseUrl &&
                <Skeleton
                    active
                    loading={dashboardData.isLoading.index}
                    className="my-4"
                >
                    {dashboardData.index && (
                        <>
                            <Paragraph strong>Repair Orders</Paragraph>
                            <CountPanel sumOrCountItems={dashboardData.index}
                                isAdmin={hasRole('admin') || hasRole('super-admin')} />
                        </>
                    )}
                </Skeleton>
            }

            <Skeleton
                active
                loading={dashboardData.isLoading.index}
                className="my-4"
            >
                {dashboardData.index?.Recycle_orderTotal && (
                    <>
                        <Paragraph strong>Recycle Orders</Paragraph>
                        <RecyleCountPanel sumOrCountItems={dashboardData.index}
                            isAdmin={hasRole('admin') || hasRole('super-admin')} />
                    </>
                )}
            </Skeleton>
            {siteLabel !== recycleBaseUrl &&
                <Skeleton
                    active
                    loading={dashboardData.isLoading.index}
                    className="my-4"
                >
                    {dashboardData.getBulkSales?.repairSchool && (
                        <>
                            <Paragraph strong>Bulk Repair List</Paragraph>
                            <BulkSchoolCountPanel sumOrCountItems={dashboardData.getBulkSales}
                                isAdmin={hasRole('admin') || hasRole('super-admin')} />
                        </>
                    )}
                </Skeleton>
            }
            <Skeleton
                active
                loading={dashboardData.isLoading.index}
                className="my-4"
            >
                {dashboardData.getBulkSales?.bulkSales && (
                    <>
                        <Paragraph strong>Bulk Recycle List</Paragraph>
                        <BulkRepairCountPanel sumOrCountItems={dashboardData.getBulkSales}
                            isAdmin={hasRole('admin') || hasRole('super-admin')} />
                    </>
                )}
            </Skeleton>



            {isAdmin && siteLabel !== recycleBaseUrl &&
                <Row className="rowgap-vbox" gutter={[24, 24]}>
                    <Col xs={24} xl={24} sm={24} md={24}>
                        <Skeleton
                            active
                            loading={dashboardData.isLoading.sales}
                            avatar
                            paragraph={{ rows: 4 }}
                            className="my-4"
                        >
                            {dashboardData.getSales && (
                                <EChart salesData={dashboardData.getSales} dateRange={dateRangeEchart}
                                    handleDateChange={handleDateChangeEchart} />
                            )}
                        </Skeleton>
                    </Col>

                    <Col xs={24} xl={24} sm={24} md={24}>
                        <Skeleton
                            active
                            loading={dashboardData.isLoading.salesTable}
                            avatar
                            paragraph={{ rows: 4 }}
                            className="my-4"
                        >
                            {dashboardData?.getSalesTable && (
                                <SalesTable salesData={dashboardData?.getSalesTable} />
                            )}
                        </Skeleton>
                    </Col>

                    <Col xs={12} xl={12} sm={24} md={24}>
                        <Skeleton
                            active
                            loading={dashboardData.isLoading.salesTable}
                            avatar
                            paragraph={{ rows: 4 }}
                            className="my-4"
                        >
                            {dashboardData?.getSalesReport && (
                                <SalesStatus salesData={dashboardData.getSalesReport} dateRange={dateRange}
                                    handleDateChange={handleDateChange} />
                            )}
                        </Skeleton>
                    </Col>

                    <Col xs={12} xl={12} sm={24} md={24}>
                        <Skeleton
                            active
                            loading={dashboardData.isLoading.payment}
                            avatar
                            paragraph={{ rows: 4 }}
                            className="my-4"
                        >
                            {dashboardData?.getPayment && (
                                <PaymentTable paymentData={dashboardData?.getPayment} />
                            )}
                        </Skeleton>
                    </Col>

                    <Col xs={12} xl={12} sm={24} md={24}>
                        <Skeleton
                            active
                            loading={dashboardData.isLoading.unPaidPayment}
                            avatar
                            paragraph={{ rows: 4 }}
                            className="my-4"
                        >
                            {dashboardData?.getUnPaidPayment && (
                                <UnpaidTable unPaidPaymentData={dashboardData?.getUnPaidPayment} />
                            )}
                        </Skeleton>
                    </Col>
                </Row>
            }
        </div>
    );
}

export default Dashboard;
