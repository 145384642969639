import React, { useState } from 'react';
import { Drawer, Button, Col, Form, Input, Row, message, Select, Typography } from 'antd';

import dayjs from 'dayjs';
import { ConfirmModal } from '../../../components/ConfirmModal';
import OrdersApi from '../../../apis/order-management/repair-order/OrdersApi';

const { Text } = Typography;
const { Option } = Select;

export default function CreateEvent({ open, setOpen, setKey, handleCancel, baseUrl, handleReset, createDate, handleHide, isFMBS }) {
    const [form] = Form.useForm();

    const [loadingObj, setLoadingObj] = useState({
        createEvent: false
    });

    const onClose = () => {
        setOpen(false);
        form.resetFields();
    };

    const onFinishFrm = async (values) => {
        setLoadingObj({ ...loadingObj, createEvent: true });

        try {
            const orderId = values.orderId;
            const orderType = values.orderType;
            let sendEmail = false;
            try {
                await ConfirmModal({
                    title: 'Email Confirmation',
                    content: 'Do you want to send the email to the customer?',
                    okText: 'Send Email',
                    cancelText: 'Cancel',
                    onOk: async () => {
                        sendEmail = true;
                    },
                    onCancel: () => {
                        //
                    }
                });
            } catch (error) {
                // Handle rejection (if 'No' is selected)
            }
            const hideLoading = message.loading('Create New Event...', 0);

            try {
                const createEvent = await OrdersApi.createEvent(baseUrl, orderId, {
                    status: 2,
                    orderId: orderId,
                    orderType: orderType,
                    est_delivery_date: createDate,
                    sendEmail: sendEmail ? 1 : 0
                });

                if (createEvent?.status === 'success') {
                    message.success(`Successfully Created`);
                    setKey(prevState => prevState + 1);
                    form.resetFields();
                    setOpen(false);
                    handleReset();
                    handleCancel();
                    handleHide();
                } else {
                    message.error(createEvent.message);
                }
            } catch (error) {
                message.error('Failed to Changing Delivery Time!');
            } finally {
                hideLoading();
            }

        } catch (error) {
            message.error(error.message);
        } finally {
            setLoadingObj({ ...loadingObj, createEvent: false });
        }
    };

    return (
        <Drawer
            width={640}
            size='large'
            placement="left"
            onClose={onClose}
            open={open}
            title={
                <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                    <Col>
                        <div className='d-grid'>
                            <Text>Change Time</Text>
                            <Text className='text-sm block' style={{ lineHeight: '8px' }}>
                                {dayjs(createDate).format('YYYY-MM-DD hh:mm A')}
                            </Text>
                        </div>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            loading={loadingObj.createEvent}
                            onClick={() => form.submit()}
                        >
                            Change Time
                        </Button>
                    </Col>
                </ Row>
            }
        >
            <Form
                form={form}
                name="ticket-form"
                onFinish={onFinishFrm}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Order Id"
                            name="orderId"
                            rules={[{ required: true, message: 'Please enter an Order ID' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="orderType"
                            className={`${isFMBS ? 'w-100' : 'd-none'}`}
                            label="Order Type"
                            rules={[{ required: true }]}
                            initialValue="repair"
                        >
                            <Select defaultValue="repair">
                                <Option value="repair">Repair</Option>
                                <Option value="direct">Direct</Option>
                            </Select>
                        </Form.Item>
                    </Col> 
                </Row>
            </Form>
        </Drawer>
    );
}
