import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Calendar, Views, DateLocalizer } from 'react-big-calendar';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { dayjsLocalizer } from 'react-big-calendar';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { message, Spin } from 'antd';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import events from './_util/events';
import DirectOrdersApi from '../../apis/order-management/direct-order/DirectOrdersApi';
import QuickEdit from '../Orders/Components/QuickEdit';
import DirectOrderQuickEdit from '../DirectOrder/Components/QuickEdit';
import CreateEvent from './Components/CreateEvent';
import { SiteOptions } from '../../components/layout/util/common';
import OrdersApi from '../../apis/order-management/repair-order/OrdersApi';
import { ConfirmModal } from '../../components/ConfirmModal';

// Configure dayjs plugins
dayjs.extend(utc);
// dayjs.extend(timezone);
dayjs.extend(localizedFormat);

// Attempt to guess the user's timezone
// const guessedTimezone = dayjs.tz.guess();
// dayjs.tz.setDefault(guessedTimezone);

const localizer = dayjsLocalizer(dayjs);

// Create DnD Calendar with proper options
const CustomizedCalendar = withDragAndDrop(Calendar);

export default function Timeline() {
  const history = useHistory();
  const baseUrl = useSelector((state) => state.auth.base_url);
  const { roles } = useSelector((state) => state.auth.user[0]);

  // Separate state for user events and holiday events
  const [userEvents, setUserEvents] = useState(events);
  const [holidayEvents, setHolidayEvents] = useState([]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [visible, setVisible] = useState(false);
  const [visibleDirectOrder, setVisibleDirectOrder] = useState(false);
  const [visibleCreateEvent, setVisibleCreateEvent] = useState(false);
  const [createDate, setCreateDate] = useState('');
  const [activeOrderId, setActiveOrderId] = useState(null);
  const [key, setKey] = useState(0);
  const [loading, setLoading] = useState(false);

  // Cache for holiday data to prevent re-fetching for the same year
  const holidaysCache = useRef({});
  const processingOrdersCache = useRef({});

  // Track if processing orders have been fetched
  const ordersFetched = useRef(false);

  // Combined events using useMemo
  const allEvents = useMemo(() => {
    // Ensure dates are properly parsed as Date objects
    const processedEvents = [...userEvents, ...holidayEvents].map(event => ({
      ...event,
      start: event.start instanceof Date ? event.start : new Date(event.start),
      end: event.end instanceof Date ? event.end : new Date(event.end)
    }));
    return processedEvents;
  }, [userEvents, holidayEvents]);

  // Memoized event styling function
  const eventStyleGetter = useCallback((event) => {
    if (event.isHoliday) {
      return {
        style: {
          backgroundColor: '#e8f4f8',
          color: '#0a5172',
          borderRadius: '4px',
          border: '1px solid #0a5172',
          fontWeight: 'bold'
        }
      };
    }
    return {};
  }, []);

  // Memoized day styling to disable Sundays
  const dayPropGetter = useCallback((date) => {
    if (dayjs(date).day() === 0) { // Sunday
      return {
        style: {
          backgroundColor: '#f2f2f2',
          cursor: 'not-allowed',
          color: '#aaa'
        }
      };
    }
    return {};
  }, []);

  // Memoized move event handler
  const moveEvent = useCallback(async ({ event, start, end }) => {
    if (event.isHoliday) return;

    const startDay = dayjs(start).day();
    const endDay = dayjs(end).day();

    const isHoliday = holidayEvents.some(event =>
      dayjs(event.start).isSame(start, 'day')
    );

    if (startDay === 0 || endDay === 0 || isHoliday) {
      alert('Events cannot be scheduled on Sundays or Holidays');
      return;
    }

    // Ensure start and end are properly formatted Date objects
    const newStart = start instanceof Date ? start : new Date(start);
    const newEnd = end instanceof Date ? end : new Date(end);

    if (event?.orderId) {
      const options = {
        weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',
        hour: '2-digit', minute: '2-digit', second: '2-digit',
        timeZoneName: 'longOffset'
      };

      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(newStart));

      setUserEvents(prevEvents =>
        prevEvents.map(prevEvent =>
          prevEvent.id === event.id ? { ...prevEvent, start: newStart, end: newEnd } : prevEvent
        )
      );

      let sendEmail = false;
      try {
        await ConfirmModal({
          title: 'Email Confirmation',
          content: 'Do you want to send the email to the customer?',
          okText: 'Send Email',
          cancelText: 'Cancel',
          onOk: async () => {
            sendEmail = true;
          },
          onCancel: () => {
            //
          }
        });
      } catch (error) {
        // Handle rejection (if 'No' is selected)
      }
      const hideLoading = message.loading('Changing Delivery Time...', 0);

      try {
        const apiMethod = event.orderNo.includes("SH")
          ? DirectOrdersApi.changeTimeLineDate
          : OrdersApi.changeTimeLineDate;

        await apiMethod(baseUrl, {
          formattedDate,
          orderId: event?.orderId,
          sendEmail: sendEmail ? 1 : 0
        });

        message.success(`Successfully Changed`);
      } catch (error) {
        message.error('Failed to Changing Delivery Time!');
      } finally {
        hideLoading();
      }
    }
  }, [holidayEvents]);
  // Memoized resize event handler
  const resizeEvent = useCallback(({ event, start, end }) => {
    if (event.isHoliday) return;

    const startDay = dayjs(start).day();
    const endDay = dayjs(end).day();

    if (startDay === 0 || endDay === 0) {
      alert('Events cannot be scheduled on Sundays');
      return;
    }

    // Ensure start and end are properly formatted Date objects
    const newStart = start instanceof Date ? start : new Date(start);
    const newEnd = end instanceof Date ? end : new Date(end);

    setUserEvents(prevEvents =>
      prevEvents.map(prevEvent =>
        prevEvent.id === event.id ? { ...prevEvent, start: newStart, end: newEnd } : prevEvent
      )
    );
  }, []);

  // Memoized handler for selecting a new event slot
  const handleSelectSlot = useCallback(({ start, end }) => {
    // Fix: This is using 'event' which is not defined in this scope
    // Should check if the slot is for a holiday instead
    const startDay = dayjs(start).day();
    const endDay = dayjs(end).day();

    // Check if the selected date is a holiday
    const isHoliday = holidayEvents.some(event =>
      dayjs(event.start).isSame(start, 'day')
    );

    if (startDay === 0 || endDay === 0 || isHoliday) {
      alert('Events cannot be scheduled on Sundays or Holidays');
      return;
    }

    const newStart = start instanceof Date ? start : new Date(start);
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'longOffset' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(newStart));

    setCreateDate(formattedDate);
    setVisibleCreateEvent(true);
  }, [holidayEvents]);


  // Memoized event select handler
  const handleSelectEvent = useCallback((event) => {
    if (event.orderNo) {
      setActiveOrderId(event.orderId);
      if (event.orderNo.includes("SH")) {
        setVisibleDirectOrder(true);
      } else {
        setVisible(true);
      }
    }
  }, []);

  const handleHide = () => {
    setVisibleDirectOrder(false);
    setVisible(false);
    setKey(prevKey => prevKey + 1);
  };

  const showDeviceCheckerModal = (id) => {
    setActiveOrderId(id);
  };

  const handleCancel = () => {
    setVisible(false);
    setVisibleDirectOrder(false);
    setActiveOrderId(null);
    setKey(prevKey => prevKey + 1);
  };

  const handleReset = () => {
    setActiveOrderId(null);
    setVisible(false);
    setVisibleDirectOrder(false);
    setKey(prevKey => prevKey + 1);
  }

  // Fetch UK holidays only if not cached for the current year
  useEffect(() => {
    const fetchHolidays = async () => {
      if (holidaysCache.current[currentYear]) {
        setHolidayEvents(holidaysCache.current[currentYear]);
        return;
      }

      const url = 'https://www.gov.uk/bank-holidays.json';

      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Failed to fetch public holidays: ${response.statusText}`);
        }

        const data = await response.json();

        if (!data['england-and-wales']?.events) {
          throw new Error('No holiday events found.');
        }

        // Map holidays to event objects
        const fetchedHolidayEvents = data['england-and-wales'].events.map(holiday => ({
          id: `holiday-${holiday.date}-${holiday.title.replace(/\s+/g, '-')}`,
          title: `🇬🇧 ${holiday.title}`,
          start: new Date(holiday.date),
          end: new Date(holiday.date),
          allDay: true,
          isHoliday: true,
        }));

        // Cache holidays for the current year
        holidaysCache.current[currentYear] = fetchedHolidayEvents;
        setHolidayEvents(fetchedHolidayEvents);

      } catch (error) {
        console.error('Error fetching public holidays:', error);
      }
    };

    const fetchProcessingOrders = async () => {
      try {
        setLoading(true);

        // Define the API requests based on baseUrl condition
        const ordersPromise = OrdersApi.list(baseUrl, {
          filters: {
            status: {
              0: 2, // Processing
              1: 9, // Processing Drop-off
            },
            is_paid: {
              0: 1, // Paid
            },
            order_no: '',
            postal_code: '',
            full_name: '',
            wfc_status: '',
            payment_type: '',
          },
          pagination: {
            current: null,
            pageSize: null,
          },
          page: null,
          per_page: null,
          current_page: null,
        });

        const directOrdersPromise =
          baseUrl === SiteOptions[0].value
            ? DirectOrdersApi.list(baseUrl, {
              status: 1, // Processing
              pagination: {
                current: null,
                pageSize: null,
              },
            })
            : Promise.resolve({ data: { data: [] } });

        // Fetch both in parallel
        const [ordersResponse, directOrdersResponse] = await Promise.all([ordersPromise, directOrdersPromise]);

        const orders = ordersResponse.data?.data || [];
        const directOrders = directOrdersResponse.data?.data || [];

        const allOrders = baseUrl === SiteOptions[0].value ? [...orders, ...directOrders] : [...orders];

        if (!allOrders.length) {
          setLoading(false);
          return;
        }

        // Map orders into event objects
        const processingOrders = allOrders.filter(order => order.order_no).map((order) => ({
          id: `processing-order-${order.order_no}`,
          orderId: order.id,
          orderNo: order.order_no,
          title: `Processing Order: ${order.order_no}`,
          start: dayjs(order.est_delivery_date).toDate(),
          // Set end time to be 1 hour after start for non-allDay events
          end: dayjs(order.est_delivery_date).add(1, 'hours').toDate(),
          allDay: false, // display in week view
          isHoliday: false,
        }));

        // Cache the processing orders for the year
        processingOrdersCache.current[currentYear] = processingOrders;

        setUserEvents((prevEvents) => {
          // Remove old processing orders to prevent duplicates
          const filteredEvents = prevEvents.filter(
            (event) => typeof event.id !== 'string' || !event.id.includes('processing-order')
          );
          return [...filteredEvents, ...processingOrders];
        });

        // Mark orders as fetched
        ordersFetched.current = true;
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching processing orders:', error);
      }
    };


    fetchHolidays();
    fetchProcessingOrders();
  }, [currentYear, baseUrl, key]);

  // Memoized navigation handler to update the current year
  const handleNavigate = useCallback((date) => {
    setCurrentYear(date.getFullYear());
  }, []);

  return (
    <>
      <Spin spinning={loading}>
        <CustomizedCalendar
          defaultDate={dayjs().toDate()}
          defaultView={Views.WEEK}
          views={[Views.DAY, Views.WEEK, Views.MONTH, Views.AGENDA]}
          events={allEvents}
          localizer={localizer}
          style={{ height: 750 }}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          selectable
          onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          popup
          resizable
          eventPropGetter={eventStyleGetter}
          dayPropGetter={dayPropGetter}
          onNavigate={handleNavigate}
          step={15}
          timeslots={4}
          // Explicitly enable drag and drop
          draggableAccessor={() => true}
          scrollToTime={dayjs().hour(9).minute(0).second(0).toDate()}
        />
      </Spin>

      {visible &&
        <QuickEdit
          visible={visible}
          handleHide={handleHide}
          setKey={setKey}
          handleCancel={handleCancel}
          activeOrderId={activeOrderId}
          handleReset={handleReset}
          baseUrl={baseUrl}
          roles={roles}
          handleOpenDeviceChecker={showDeviceCheckerModal}
        />
      }

      {visibleDirectOrder &&
        <DirectOrderQuickEdit
          visible={visibleDirectOrder}
          handleHide={handleHide}
          setKey={setKey}
          handleCancel={handleCancel}
          activeOrderId={activeOrderId}
          handleReset={handleReset}
          baseUrl={baseUrl}
          roles={roles}
          handleOpenDeviceChecker={showDeviceCheckerModal}
        />
      }

      {visibleCreateEvent &&
        <CreateEvent
          open={visibleCreateEvent}
          setOpen={setVisibleCreateEvent}
          baseUrl={baseUrl}
          handleReset={handleReset}
          handleHide={handleHide}
          setKey={setKey}
          createDate={createDate}
          handleCancel={handleCancel}
          isFMBS={baseUrl === SiteOptions[0].value}
        />
      }
    </>
  )
}

Timeline.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
}