import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import Profile from "../pages/Profile";

import SignIn from "../pages/SignIn";

import Blank from "../pages/Blank";
import BlankCreateEdit from "../pages/Blank/CreateEditForm";

import Orders from "../pages/Orders";
import OrdersTimeline from "../pages/Timelines";

import RecycleOrders from "../pages/RecycleOrders";
import DirectOrder from "../pages/DirectOrder";
import VatInvoice from "../pages/VatInvoice";

import BulkRecycle from "../pages/BulkRecycle"
import BulkRepair from "../pages/BulkRepair"
import logs from "../pages/Logs"
import Reason from "../pages/Reason"
import PromoCode from "../pages/PromoCode"
import Customers from "../pages/Customers"
import Workforce from "../pages/Workforce"
import User from "../pages/User"

import ProductSeries from "../pages/Settings/ProductSeries"
import ProductCategory from "../pages/Settings/ProductCategory"
import Services from "../pages/Settings/Services"
import ConditionDescription from "../pages/Settings/ConditionDescription"
import Product from "../pages/Settings/Product"
import Condition from "../pages/Settings/Condition"

import Invoice from "../pages/Invoice";
import InvoiceStripe from "../pages/Invoice/Stripe";
import InvoicePaypal from "../pages/Invoice/Paypal";
import InvoiceCreateEdit from "../pages/Invoice/CreateEditForm";

import Notifications from "../pages/Notifications"
import NotificationsCreateEdit from "../pages/Notifications/Components/CreateEditForm";

import Deliveries from "../pages/Deliveries"
import DeliveriesCreateEdit from "../pages/Deliveries/Components/CreateEditForm";
import DeliveriesSingleView from "../pages/Deliveries/Components/SingleView";

import WaitingForConformation from "../pages/WaitingForConformation"
import WaitingForConformationCreateEdit from "../pages/WaitingForConformation/Components/CreateEditForm";
import WaitingForConformationSingleView from "../pages/WaitingForConformation/Components/SingleView";
import Chat from "../pages/Chat";

const routes = [
    { path: "/sign-in", name: 'sign-in', component: SignIn, title: 'Sign In - FMBS', exact: true },

    { path: ['/', '/dashboard'], name: 'dashboard', component: Dashboard, title: 'Dashboard - FMBS', exact: true, protected: true, },
    { path: '/profile', name: 'profile', component: Profile, title: 'Profile - FMBS', exact: true, protected: true, },

    { path: '/home', name: 'home', component: Home, title: 'Home - FMBS', exact: true, protected: true, },
    { path: "/blank", name: 'blank', component: Blank, title: 'Blank - FMBS', exact: true, protected: true, },
    { path: "/blank/create", name: 'create-blank', component: BlankCreateEdit, title: 'Create - Blank', protected: true, },
    { path: "/blank/edit/:id", name: 'edit-blank', component: BlankCreateEdit, title: 'Edit - Blank', protected: true, },
    { path: "/blank/view/:id", name: 'view-blank', component: BlankCreateEdit, title: 'View - Blank', protected: true, },

    { path: "/orders", name: 'orders', component: Orders, title: 'Orders - FMBS', exact: true, protected: true, isHideMenubar: true },
    { path: "/timeline", name: 'timeline', component: OrdersTimeline, title: 'Timeline - FMBS', exact: true, protected: true, isHideMenubar: true },

    { path: "/recycle-orders", name: 'recycle-orders', component: RecycleOrders, title: 'Recycle Orders - FMBS', exact: true, protected: true, isHideMenubar: true },

    { path: "/direct-orders", name: 'Direct Orders', component: DirectOrder, title: 'Direct Order - FMBS', exact: true, protected: true, isHideMenubar: true },

    { path: "/chat", name: 'chat', component: Chat, title: 'Chat - FMBS', exact: true, protected: true, },

    { path: "/bulk-recycle", name: 'bulk-recycle', component: BulkRecycle, title: 'Bulk Recycle - FMBS', exact: true, protected: true, },
    { path: "/bulk-repair", name: 'bulk-repair', component: BulkRepair, title: 'Bulk Repair - FMBS', exact: true, protected: true, },
    { path: "/logs", name: 'logs', component: logs, title: 'Logs - FMBS', exact: true, protected: true, },
    { path: "/reason", name: 'reason', component: Reason, title: 'Reason - FMBS', exact: true, protected: true, },
    { path: "/workforce", name: 'workforce', component: Workforce, title: 'Reason - FMBS', exact: true, protected: true, },
    { path: "/user", name: 'user', component: User, title: 'User - FMBS', exact: true, protected: true, },

    { path: "/settings/product-series", name: 'product-series', component: ProductSeries, title: 'Product Series - FMBS', exact: true, protected: true, },
    { path: "/settings/product-category", name: 'product-category ', component: ProductCategory, title: 'Product Category  - FMBS', exact: true, protected: true, },
    { path: "/settings/services", name: 'services ', component: Services, title: 'Services  - FMBS', exact: true, protected: true, },
    { path: "/settings/condition-description", name: 'Condition Description ', component: ConditionDescription, title: 'Condition Description  - FMBS', exact: true, protected: true, },
    { path: "/settings/products", name: 'product ', component: Product, title: 'Product  - FMBS', exact: true, protected: true, },
    { path: "/settings/device-condition", name: 'condition ', component: Condition, title: 'condition  - FMBS', exact: true, protected: true, },

    { path: "/customers", name: 'customers', component: Customers, title: 'Customers - FMBS', exact: true, protected: true, },
    { path: "/promo-code", name: 'Promo Code', component: PromoCode, title: 'Promo Code - FMBS', exact: true, protected: true, },

    { path: "/invoice", name: 'invoice', component: Invoice, title: 'Invoice - FMBS', exact: true, protected: true, },
    { path: "/invoice/create", name: 'create-invoice', component: InvoiceCreateEdit, title: 'Create - Invoice', protected: true, },
    { path: "/invoice/stripe", name: 'stripe', component: InvoiceStripe, title: 'Stripe - Invoice', protected: true, },
    { path: "/invoice/paypal", name: 'paypal', component: InvoicePaypal, title: 'Paypal - Invoice', protected: true, },

    { path: "/vat-invoice", name: 'Vat - Invoice', component: VatInvoice, title: 'VAT - Invoice', protected: true, },

    { path: "/notifications", name: 'notifications', component: Notifications, title: 'Notifications - FMBS', exact: true, protected: true, },
    { path: "/notifications/create", name: 'create-notifications', component: NotificationsCreateEdit, title: 'Create - Notifications', protected: true, },
    { path: "/notifications/edit/:id", name: 'edit-notifications', component: NotificationsCreateEdit, title: 'Edit - Notifications', protected: true, },

    { path: "/deliveries", name: 'deliveries', component: Deliveries, title: 'Deliveries - FMBS', exact: true, protected: true, },
    { path: "/deliveries/create", name: 'create-deliveries', component: DeliveriesCreateEdit, title: 'Create - Deliveries', protected: true, },
    { path: "/deliveries/edit/:id", name: 'edit-deliveries', component: DeliveriesCreateEdit, title: 'Edit - Deliveries', protected: true, },
    { path: "/deliveries/view/:id", name: 'view-deliveries', component: DeliveriesSingleView, title: 'View - Deliveries', protected: true, },

    { path: "/waiting-for-conformation", name: 'waiting-for-conformation', component: WaitingForConformation, title: 'WFC - FMBS', exact: true, protected: true, },
    { path: "/waiting-for-conformation/create", name: 'create-waiting-for-conformation', component: WaitingForConformationCreateEdit, title: 'Create - WFC', protected: true, },
    { path: "/waiting-for-conformation/edit/:id", name: 'edit-waiting-for-conformation', component: WaitingForConformationCreateEdit, title: 'Edit - WFC', protected: true, },
    { path: "/waiting-for-conformation/view/:id", name: 'view-waiting-for-conformation', component: WaitingForConformationSingleView, title: 'View - WFC', protected: true, },
];

export { routes };