import { Button, Card, Col, List, Row, Space, Table, Tooltip, Typography } from 'antd';
import React, { useRef, useMemo } from 'react';
import { PrinterOutlined } from '@ant-design/icons';

import styles from "../../Orders.module.scss";

import ListItem from '../ListItem';
import PaymentDetails from '../PaymentDetails';
import { getPassCodeOptionByValue } from '../../util/common';
import dayjs from 'dayjs';

const { Text } = Typography;

const columns = [
    {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
    },
    {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
        ellipsis: {
            showTitle: false,
        },
        render: (service) => (
            <Tooltip placement="topLeft" title={service}>
                {service}
            </Tooltip>
        ),
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (price) => "£" + price,
        width: 100,
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
        width: 50,
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        render: (_, record) => "£" + record.price * record.qty,
        width: 100,
    },
];

export default function OrderItems({ activeOrder, repairTotal, subtotal, discount, netTotal, priorityCharge, baseUrl, setOpenPaymentDetails, openPaymentDetails, setVisibleDeviceChecker, isShort }) {
    const orderSummeryRef = useRef(null);

    const showPaymentDetails = () => {
        orderSummeryRef.current.scrollIntoView();
        setOpenPaymentDetails(true);
    }

    const passcodeOption = useMemo(
        () => activeOrder?.passcode_option ? getPassCodeOptionByValue(activeOrder.passcode_option, !!activeOrder.passcode) : null,
        [activeOrder?.passcode_option, activeOrder?.passcode]
    );
    const returnShipping = activeOrder.order_shipments?.find(shipment => shipment.is_shipping === 1 && shipment.type === 1)?.amount || "-";
    const sendingShipment = activeOrder.order_shipments?.find(shipment => shipment.is_shipping === 1 && shipment.type === 0)?.amount || "-";
    const collection_date = activeOrder.order_shipments?.find(shipment => shipment.is_shipping === 1 && shipment.type === 0)?.collection_date;

    const expandedRowRender = (record) => {
        const expandedColumns = [
            ...(record.node ? [{
                title: 'Node',
                key: 'node',
                dataIndex: "node",
                render: (_, { node }) => node || "-",
            }] : []),
            ...(record.standard_time?.title ? [{
                title: 'Standard Time',
                key: 'standard_time',
                dataIndex: "standard_time",
                render: (_, record) => record.standard_time?.title || "-",
            }] : [])
        ];

        return expandedColumns.length > 0 ? (
            <Table
                columns={expandedColumns}
                dataSource={[record]}
                pagination={false}
                size="small"
                rowKey="key"
            />
        ) : null;
    };
    return (
        <>
            <Card
                title={!isShort && (<Text>Order Details</Text>)}
                extra={!isShort && (
                    <Space>
                        <Tooltip title="Print Label">
                            <Button
                                type="primary"
                                onClick={() => setVisibleDeviceChecker(true)}
                                icon={<PrinterOutlined />}
                            />
                        </Tooltip>

                        <Tooltip title="Check the Payments">
                            <Button danger onClick={showPaymentDetails}>Check Payment</Button>
                        </Tooltip>
                    </Space>
                )}
            >
                <Row gutter={16} className={styles.model_card_row}>
                    <Col span={17}>
                        {activeOrder.order_repair && (
                            <Table
                                size="small"
                                columns={columns}
                                dataSource={activeOrder.order_repair.map(item => ({
                                    key: item.id,
                                    model: item.products?.model,
                                    service: item.service?.name,
                                    price: item.amount,
                                    qty: item.qty,
                                    note: item.note,
                                    standard_time: item.products?.product_services_with_standard_time?.find(ps => ps.service_id === item.service?.id)?.standard_time || null,
                                }))}
                                expandable={{
                                    expandedRowRender,
                                    rowExpandable: (record) => record.node || record.standard_time?.title,
                                    defaultExpandedRowKeys: activeOrder.order_repair.length > 0
                                        ? [activeOrder.order_repair[0].id]
                                        : [],
                                }}
                                rowKey={(record) => record.key}
                            />
                        )}
                    </Col>
                    <Col
                        ref={orderSummeryRef}
                        span={7}
                    >
                        <List
                            size="small"
                            header={<Text>Order Summery</Text>}
                            bordered
                            dataSource={[
                                <ListItem key={0} keyText="Repair Total: " valueText={'£' + repairTotal.toFixed(2)} />,
                                <ListItem
                                    key={2}
                                    keyText="Postage - Sending Your Device to Us:"
                                    valueText={sendingShipment !== "-" ? "£" + parseFloat(sendingShipment).toFixed(2) : "-"}
                                />,
                                <ListItem
                                    key={1}
                                    keyText="Postage - Receiving Your Device After Repair:"
                                    valueText={returnShipping !== "-" ? "£" + parseFloat(returnShipping).toFixed(2) : "-"}
                                />,
                                <ListItem key={3} keyText="Priority Fee: " valueText={(activeOrder.order_priority && activeOrder.order_priority?.is_priority === 1) ? "£" + priorityCharge : "-"} />,
                                <ListItem key={4} keyText="Sub Total: " valueText={'£' + subtotal.toFixed(2)} />,
                                <ListItem key={5} keyText="Discount (£):" valueText={activeOrder.coupon ? "-£" + discount.toFixed(2) : "-"} />,
                                <ListItem key={6} keyText="Net Total: " valueText={'£' + netTotal.toFixed(2)} />,
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>

                    {!isShort &&
                        <Col span={24}>
                            <Space.Compact block
                                direction="vertical"
                                span={24}
                                className='mt-4'
                            >
                                <Text strong>Passcode Option:</Text>
                                {passcodeOption ? (
                                    <Space
                                        direction='vertical'>
                                        <Text type="secondary" className={`mb-${activeOrder?.passcode_option ? 1 : 0}`}>
                                            {passcodeOption.label || '-'}
                                        </Text>
                                        <Text type="secondary">{passcodeOption.description}</Text>
                                        {passcodeOption.content &&
                                            <Text type="secondary" copyable={{ text: passcodeOption.content }}>
                                                {passcodeOption.content}
                                            </Text>
                                        }
                                    </Space>
                                ) : (
                                    <Text type="secondary" className="mb-0">-</Text>
                                )}

                                {activeOrder?.passcode && (
                                    <Text>
                                        Passcode: <Text underline copyable={{ text: activeOrder.passcode }}>{activeOrder.passcode}</Text>
                                    </Text>
                                )}

                                {collection_date &&
                                    <Space className='mt-4'>
                                        <Text strong>Collection Date:</Text>
                                        <Text type="secondary" className='mb-2' mark>{collection_date}</Text>
                                    </Space>
                                }

                                {activeOrder?.est_delivery_date &&
                                    <Space className='mt-4'>
                                        <Text strong>Est. Delivery:</Text>
                                        <Text type="secondary" className='mb-2'>{dayjs(activeOrder?.est_delivery_date).format("DD-MM-YYYY hh:mm A")}</Text>
                                    </Space>
                                }

                                <Text className='mt-4' strong>Order Note:</Text>
                                <Text mark={!!activeOrder?.order_note}>{activeOrder?.order_note ?? "-"}</Text>
                            </Space.Compact>
                        </Col>
                    }
                </Row>
            </Card>

            {!isShort &&
                <PaymentDetails
                    openPaymentDetails={openPaymentDetails}
                    setOpenPaymentDetails={setOpenPaymentDetails}
                    activeOrder={activeOrder}
                    baseUrl={baseUrl}
                />
            }
        </>
    )
}
