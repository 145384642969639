import { ExclamationCircleOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';

import { Button, Col, Form, Input, Modal, Row, Select, message, Drawer, DatePicker, Typography, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ProductSeriesApi from '../../../../apis/settings/ProductSeriesApi';
import { SiteOptions } from '../../../../components/layout/util/common';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeSeries, handleReset, productCategory, roles, setIndex, baseUrl }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    
    const tradeBaseUrl = SiteOptions[5].value === baseUrl;
    const [editorData, setEditorData] = useState((activeSeries?.content) ?? null);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (activeSeries) {
            form.setFieldsValue({
                name: activeSeries?.name,
                slug: activeSeries?.slug,
                category_id: activeSeries?.category_id,
            });
        }
    }, [activeSeries, form]);

    const onFinish = async (values) => {
        try {

            const formData = new FormData();
            for (const key in values) {
                if (key === 'image' && values[key]) {
                    formData.append(key, values[key].file);
                } else {
                    formData.append(key, values[key]);
                }
            }

            formData.append('editorData', editorData);

            if (activeSeries?.id) {
                formData.append('id', activeSeries.id);
            }

            try {

                const updateSeries = await ProductSeriesApi.createSeries(baseUrl, formData);
                if (updateSeries) {
                    message.success('Series is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeSeries.id]);

        try {
            const deleteSeries = await ProductSeriesApi.deleteSeries(baseUrl, formData, activeSeries.id);
            if (deleteSeries) {
                message.success('Series is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeSeries.emp_name} - ${activeSeries.emp_slug}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }

    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    const [isImage, setIsImage] = useState(false);

    const handleChangeName = (e) => {
        const slugName = e.target.value;
        form.setFieldsValue({
            slug: slugName.toLowerCase().replace(/\s+/g, '-'),
        });
    }

    const handleUploadChange = (info) => {
        const { fileList } = info;

        form.setFieldsValue({
            image: fileList[0] || null,
        });
    };

    return (
        <Drawer
            push={false}
            key="notification-details-model"

            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <Text>Product Series Form</Text>

                    <div className='d-flex'>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            className='mx-2'
                            form='series-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeSeries?.id ? "Update" : "Create"}
                        </Button>
                    </div>
                </Col>
            </Row>}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={1000}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeSeries && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="series-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Row gutter={[24, 0]}>

                    <Col span={23}>
                        <Row gutter={[24, 0]}>

                            <Col span={8}>
                                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                                    <Input placeholder='Series Name' onChange={handleChangeName} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="slug" label="Slug" rules={[{ required: true }]}>
                                    <Input placeholder='Slug' />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="category_id" label="Product Category" rules={[{ required: true }]} labelCol={{ span: 24 }}>

                                    <Select
                                        optionLabelProp="children"
                                        placeholder="Select Category"
                                    >
                                        {(productCategory?.data ?? []).map((item, idx) => (
                                            <Option key={idx} value={item.id}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {!tradeBaseUrl && (
                                <>
                                    <Col span={8}>
                                        {activeSeries?.image_url && !isImage ? (
                                            <>
                                                <div className='mb-4'>
                                                    <Text>Image</Text><br />
                                                    <Link
                                                        to={{ pathname: activeSeries.image_url }}
                                                        target="_blank"
                                                        className="btn-sign-in"
                                                    >
                                                        view <EyeOutlined />
                                                    </Link>
                                                    <Button type="primary" className='mx-4' onClick={() => setIsImage(true)}>Change</Button>
                                                </div>
                                            </>
                                        ) : (
                                            <Form.Item name="image" label="Image" rules={[{ required: true }]}>
                                                <Upload
                                                    listType="picture"
                                                    maxCount={1}
                                                    beforeUpload={() => false}
                                                    onChange={handleUploadChange}
                                                >
                                                    <Button type="primary" icon={<UploadOutlined />}>
                                                        Upload
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        )}
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item name="content" label="Content" labelCol={{ span: 24 }}>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={editorData}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setEditorData(data);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}
