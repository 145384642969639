import React, {Fragment} from 'react'
import {
  Br,
  Cut,
  Line,
  Printer,
  Row,
  Text,
  Image,
  QRCode
} from 'react-thermal-printer';
import { transforms } from '@react-thermal-printer/image';
import dayjs from 'dayjs';

import logo from "../../assets/images/site-logo.png";

export default function ThermalReceipt({ activeOrder }) {
  return (
    <Printer type="epson" characterSet="wpc1252">
      <Image
        align="center"
        src={logo}
        transforms={[transforms.floydSteinberg]}
        width={150}
        alt='Fixmybrokenscreen.Co.Uk'
      />
      <Text size={{ width: 2, height: 2 }} align="center" bold={true}>
        Fixmybrokenscreen.Co.Uk
      </Text>
      <Text align="center" bold={true}>LTD</Text>
      <Br />

      {/* Address */}
      <Text align="center">23 Whitechapel Road, London, E1 1DU.</Text>
      <Br />

      {/* Contact Information */}
      <Row
        right={<Text>Phone: 020 8064 2362</Text>}
        left={<Text>WhatsApp: '078 288 03645</Text>}
      />
      <Br />

      <Line />

      {/* Order Information */}
      <Row
        left={<Text>Order No.</Text>}
        right={<Text>{activeOrder?.order_no}</Text>}
      />
      <Row
        left={<Text>Date:</Text>}
        right={<Text>{activeOrder?.created_at ? new Date(activeOrder.created_at).toLocaleDateString() : ''}</Text>}
      />
      <Row
        left={<Text>Customer:</Text>}
        right={<Text>{activeOrder?.user?.customer_name}</Text>}
      />

      <Line />

      {/* Order Items */}
      {activeOrder?.items.map((item, index) => (
        <Fragment key={index}>
          <Text bold={true}>{item?.model_name}</Text>
          <Row
            left={<Text>{item?.fault_note || `${item?.quantity} X ${parseFloat(item?.unit_price).toFixed(2)}`}</Text>}
            right={<Text>{`£${(parseFloat(item?.quantity) * parseFloat(item?.unit_price)).toFixed(2)}`}</Text>}
          />

          {item?.est_collection_time && (
            <Text>Collection Time: {dayjs(item.est_collection_time).format("DD-MM-YYYY hh:mm A")}</Text>
          )}

          {item?.note && (
            <Text>Note: {item.note}</Text>
          )}

          <Line type="dotted" />
        </Fragment>
      ))}

      {/* Totals */}
      <Row
        left={<Text>Total:</Text>}
        right={<Text>{`£${activeOrder?.repair_cost}`}</Text>}
      />
      <Row
        left={<Text>Deposit Amount:</Text>}
        right={<Text>{`£${activeOrder?.deposit_amount}`}</Text>}
      />
      <Row
        left={<Text bold={true}>Due Balance:</Text>}
        right={<Text bold={true}>{`£${activeOrder?.due_amount}`}</Text>}
      />

      <Line />

      {/* Thank You Message */}
      <Br />
      <Text align="center">Thank you for choosing us.</Text>
      <Text align="center" bold={true}>Fixmybrokenscreen.Co.Uk</Text>
      <Br />

      <QRCode align="center" content={activeOrder?.order_no} />
      <Br />

      {/* Cut the paper */}
      <Cut />
    </Printer>
  )
}
