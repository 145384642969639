import { useCallback } from "react";
import xlsx from "better-xlsx";
import objectPath from "object-path";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import dayjs from "dayjs";

import "../util/jsPdfFont";

const useExport = ({
  columns,
  data,
  fileName,
  pdfTheme,
  exportOptions = {} // Additional customization options
}) => {
  // Default export configuration
  const defaultExportConfig = {
    // Custom value extractors for specific column types or keys
    valueExtractors: {
      // Example of a custom extractor for dates
      date: (value) => {
        if (!value) return "-";
        try {
          return dayjs(value).format('MMMM Do YYYY, h:mm:ss a');
        } catch {
          return value;
        }
      },
      // Example of a custom extractor for full names
      fullName: (value, record) => {
        if (record) {
          const first = objectPath.get(record, 'first_name', '');
          const last = objectPath.get(record, 'last_name', '');
          return `${first} ${last}`.trim();
        }
        return value;
      }
    },
    // Custom renderers for specific column types
    renderExtractors: {
      // Function to extract text from React components
      default: (renderResult) => {
        // If render returns a React element, try to extract text or label
        if (renderResult && typeof renderResult === 'object') {
          return renderResult.props?.label ||
            renderResult.props?.children ||
            renderResult.props?.type ||
            "";
        }
        return renderResult;
      }
    }
  };

  // Merge default config with user-provided export options
  const exportConfig = {
    ...defaultExportConfig,
    ...exportOptions,
    valueExtractors: {
      ...defaultExportConfig.valueExtractors,
      ...(exportOptions.valueExtractors || {})
    },
    renderExtractors: {
      ...defaultExportConfig.renderExtractors,
      ...(exportOptions.renderExtractors || {})
    }
  };

  // Helper function to extract value for a column
  const extractColumnValue = useCallback((column, record, rowIndex) => {
    try {
      let value;

      // First, try to get value using dataIndex
      if (column.dataIndex) {
        value = objectPath.get(record, column.dataIndex);
      }

      // Check if there's a specific value extractor for this column
      const columnKey = column.key || column.dataIndex;
      const valueExtractor = exportConfig.valueExtractors[columnKey];
      if (valueExtractor) {
        value = valueExtractor(value, record);
      }

      // If render function exists, use it to process the value
      if (typeof column.render === 'function') {
        const renderResult = column.render(value, record, rowIndex);

        // Use custom render extractor or default
        const renderExtractor = exportConfig.renderExtractors[columnKey] ||
          exportConfig.renderExtractors.default;
        value = renderExtractor(renderResult);
      }

      // Final fallback
      return value !== undefined && value !== null ? value.toString() : "";
    } catch (error) {
      console.error(`Error extracting value for column ${column.key}:`, error);
      return "";
    }
  }, [exportConfig]);

  // Helper function to determine if a column should be exported
  const shouldExportColumn = (column) => {
    return column.exportable !== false;
  };

  const onExcelPrint = useCallback(() => {
    // Validate inputs
    if (!columns || columns.length === 0) {
      console.error("No columns provided for Excel export");
      return;
    }
    if (!data || data.length === 0) {
      console.error("No data provided for Excel export");
      return;
    }

    const file = new xlsx.File();
    const sheet = file.addSheet("Sheet1");
    const headerRow = sheet.addRow();

    // Get exportable columns
    const exportableColumns = columns.filter(shouldExportColumn);

    if (exportableColumns.length === 0) {
      console.error("No exportable columns found. Make sure columns don't all have exportable: false");
      return;
    }

    // Add headers
    exportableColumns.forEach(({ title }) => {
      const cell = headerRow.addCell();
      cell.value = title || "";
    });

    // Add data rows
    data.forEach((record, rowIndex) => {
      const row = sheet.addRow();
      exportableColumns.forEach((column) => {
        const cell = row.addCell();
        cell.value = extractColumnValue(column, record, rowIndex);
      });
    });

    file.saveAs("blob").then((blob) => {
      saveAs(blob, `${fileName || "export"}.xlsx`);
    }).catch(error => {
      console.error("Error saving Excel file:", error);
    });
  }, [columns, data, fileName, extractColumnValue]);

  const onCsvPrint = useCallback(() => {
    // Validate inputs
    if (!columns || columns.length === 0) {
      console.error("No columns provided for CSV export");
      return;
    }
    if (!data || data.length === 0) {
      console.error("No data provided for CSV export");
      return;
    }

    // Get exportable columns
    const exportableColumns = columns.filter(shouldExportColumn);

    if (exportableColumns.length === 0) {
      console.error("No exportable columns found. Make sure columns don't all have exportable: false");
      return;
    }

    let csv = "";

    // Add headers
    exportableColumns.forEach(({ title }, index) => {
      if (index !== 0) csv += ",";
      // Properly quote CSV fields
      csv += `"${(title || "").toString().replaceAll('"', '""')}"`;
    });
    csv += "\n";

    // Add data rows
    data.forEach((record, rowIndex) => {
      exportableColumns.forEach((column, colIndex) => {
        if (colIndex !== 0) csv += ",";

        const value = extractColumnValue(column, record, rowIndex);

        // Properly quote CSV fields
        csv += `"${value.replaceAll('"', '""')}"`;
      });
      csv += "\n";
    });

    saveAs(new Blob([csv], { type: "text/csv;charset=utf-8" }), `${fileName || "export"}.csv`);
  }, [columns, data, fileName, extractColumnValue]);

  const onPdfPrint = useCallback(() => {
    // Validate inputs
    if (!columns || columns.length === 0) {
      console.error("No columns provided for PDF export");
      return;
    }
    if (!data || data.length === 0) {
      console.error("No data provided for PDF export");
      return;
    }

    // Get exportable columns
    const exportableColumns = columns.filter(shouldExportColumn);

    if (exportableColumns.length === 0) {
      console.error("No exportable columns found. Make sure columns don't all have exportable: false");
      return;
    }

    const doc = new jsPDF();

    try {
      doc.setFont("FreeSans");

      const head = [exportableColumns.map(c => c.title || "")];

      const body = data.map((record, rowIndex) =>
        exportableColumns.map((column) =>
          extractColumnValue(column, record, rowIndex)
        )
      );

      autoTable(doc, {
        styles: { font: "FreeSans" },
        headStyles: { fontStyle: "normal" },
        head: head,
        body: body,
        theme: pdfTheme || "striped",
      });

      doc.save(`${fileName || "export"}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }, [columns, data, fileName, pdfTheme, extractColumnValue]);

  return {
    onExcelPrint,
    onCsvPrint,
    onPdfPrint,
  };
};

export default useExport;

// Usage example for custom export options
// const customExportOptions = {
//   valueExtractors: {
//     // Custom extractor for a specific column
//     'order_no': (value) => `Order-${value}`,
//     // Override date formatting
//     'created_at': (value) => dayjs(value).format('YYYY-MM-DD')
//   },
//   renderExtractors: {
//     // Custom render extractor for specific columns
//     'status': (renderResult) => renderResult.props.value
//   }
// };

// const { onExcelPrint, onCsvPrint, onPdfPrint } = useExport({
//   columns: defaultColumns,
//   data: tableData,
//   fileName: 'my-export',
//   exportOptions: customExportOptions
// });