import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Space, Select, message, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DirectOrdersApi from '../../../../apis/order-management/direct-order/DirectOrdersApi';
import dayjs from 'dayjs';

const { useForm } = Form;

export default function UpdateOrderItem({ visible, handleCancel, activeOrderId, activeOrder, setKey, setActiveKey, activeItem }) {
    const baseUrl = useSelector((state) => state.auth.base_url);
    const [form] = useForm();

    useEffect(() => {
        if (!activeItem) {
            form.resetFields();
        }
    }, []);

    const [loadingObj, setLoadingObj] = useState({
        previewLoading: false,
        sendLoading: false,
    });

    return (
        <Drawer
            open={visible}
            title="Add New Item"
            placement='left'
            onClose={handleCancel}
            width={1200}
            destroyOnClose
            extra={
                <Space>
                    <Button
                        type="default"
                        onClick={() => {
                            form.resetFields();
                        }}>
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        loading={loadingObj.sendLoading}
                        onClick={() => {
                            setLoadingObj(prevState => ({ ...prevState, sendLoading: true }));
                            form
                                .validateFields()
                                .then(async (values) => {
                                    values.OrderId = activeOrderId;
                                    values.activeItemId = (activeItem?.key) ?? null;
                                    const response = await DirectOrdersApi.createNewItem(baseUrl, values);
                                    handleCancel();
                                    setLoadingObj(prevState => ({ ...prevState, sendLoading: false }));
                                    message.success(response.success);

                                    // Refresh the orders list
                                    setKey(prevKey => prevKey + 1);

                                    // Refresh the order details
                                    setActiveKey(prevKey => prevKey + 1);

                                    form.resetFields();
                                })
                                .catch((info) => {
                                    setLoadingObj(prevState => ({ ...prevState, sendLoading: false }));
                                });
                        }}>
                        {activeItem ? 'Update' : 'Save'}
                    </Button>
                </Space>
            }
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={{
                            devices: [{
                                qty: activeItem?.qty ?? 1,
                                model: activeItem?.model ?? null,
                                fault_note: activeItem?.fault_note ?? null,
                                imei_no: activeItem?.imei ?? null,
                                password: activeItem?.password ?? null,
                                collection_time: activeItem?.est_collection_time ? dayjs(activeItem?.est_collection_time) : null,
                                note: activeItem?.note ?? null,
                                amount: activeItem?.price ?? 0,
                            }],
                            cost_id: parseInt(activeItem?.cost_id) || activeOrder.costs.find((cost) => parseFloat(cost.amount) === parseFloat(activeItem?.price))?.id || null,
                        }}
                    >

                        <Row gutter={16}>
                            <Col span={6} className={`${(activeItem && !activeItem?.cost_id) ? '' : 'd-none'}`}>
                                <Form.Item
                                    name="cost_id"
                                    label="Select a previous cost"
                                    rules={[
                                        {
                                            required: (activeItem && !activeItem?.cost_id) ? true : false,
                                            message: 'Please select a cost',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select a particular cost"
                                        options={activeOrder.costs.map((cost) => ({
                                            label: `${cost.description} - ${cost.amount}`,
                                            value: cost.id
                                        }))}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.List name="devices">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <>
                                                    <Row gutter={24} key={`device-${key}`} className='pt-2'>
                                                        <Col span={4}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'model']}
                                                                label={`Model ${key + 1}`}
                                                                rules={
                                                                    [
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing Model Name',
                                                                        },
                                                                    ]}
                                                            >
                                                                <Input placeholder="Model Name" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={3}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'imei_no']}
                                                                label="Imei No"
                                                            >
                                                                <Input placeholder="Imei No" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={3} className='d-none'>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'qty']}
                                                                label="Qty"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Missing qty',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber size="large" min={1} placeholder="Qty" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'fault_note']}
                                                                label="Service Required"
                                                                className='mb-0'
                                                            >
                                                                <Input.TextArea placeholder="Service Required" rows={3} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'password']}
                                                                label="Password"
                                                            >
                                                                <Input placeholder="Password" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={5}>
                                                            <Form.Item {...restField} name={[name, 'collection_time']} label="Collection Time">
                                                                <DatePicker
                                                                    showTime={{
                                                                        format: 'HH:mm',
                                                                        minuteStep: 15,
                                                                    }}
                                                                    format="YYYY-MM-DD HH:mm"
                                                                    disabledDate={(current) => {
                                                                        const today = dayjs().startOf('day');
                                                                        return current && (current < today || current.day() === 0);
                                                                    }}
                                                                    placeholder="Select Date & Time"
                                                                    style={{ width: '100%' }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={3}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'amount']}
                                                                label="Amount"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Missing amount',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    size="large"
                                                                    className='w-100'
                                                                    placeholder="Amount"
                                                                    min={0}
                                                                    formatter={(value) => `£${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={1} className={`${activeItem ? 'd-none' : 'd-flex align-center justify-start'}`}>
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </Col>
                                                        <Col span={7} >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'note']}
                                                                label="Note" >
                                                                <Input.TextArea placeholder="Note" rows={3} />
                                                            </Form.Item>
                                                        </Col>

                                                    </Row>
                                                </>
                                            ))}
                                            <Form.Item label="">
                                                <Button
                                                    size='middle'
                                                    shape="round"
                                                    type="dashed"
                                                    className={`mt-4 ${activeItem ? 'd-none' : ''}`}
                                                    onClick={() => add({ service: '', amount: 0, qty: 1, note: '' })}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add field
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Drawer >
    );
}
