import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, InputNumber, List, Row, Space, Typography, message, DatePicker } from 'antd';
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import dayjs from 'dayjs';

import ListItem from './ListItem';
import DirectOrdersApi from '../../../apis/order-management/direct-order/DirectOrdersApi';
import { Receipt } from '../../../components/ReceiptPrint';

const { Text } = Typography;
const { useForm } = Form;

const CollectionCreateForm = ({ visible, onCancel, setKey }) => {
    const baseUrl = useSelector((state) => state.auth.base_url);
    const [form] = useForm();

    const [loadingObj, setLoadingObj] = useState({
        previewLoading: false,
        sendLoading: false,
    });

    const initialTotal = {
        orderTotal: 0,
        subTotal: 0,
        netTotal: 0,
        deposit_amount: 0,
        balance_amount: 0,
    };
    const [totals, setTotals] = useState(initialTotal);
    const [orderDetails, setOrderDetails] = useState(null);

    const processedFieldsChange = (_, allFields) => {
        let newOrderTotal = 0;
        let newSubTotal = 0;
        let newNetTotal = 0;
        let deposit_amount = 0;
        let balance_amount = 0;
        if (allFields.devices) {
            allFields.devices.forEach(field => {
                const { amount, qty } = field;
                newOrderTotal += amount * +qty;
            });
            newSubTotal = newOrderTotal;
            newNetTotal = newOrderTotal;
        }

        if (allFields.deposit_amount) {
            deposit_amount = allFields.deposit_amount;
            balance_amount = newSubTotal - allFields.deposit_amount;
        }

        setTotals({
            orderTotal: newOrderTotal,
            subTotal: newSubTotal,
            netTotal: newNetTotal,
            deposit_amount: parseFloat(deposit_amount),
            balance_amount: balance_amount,
        });
    };

    const receiptRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => receiptRef.current,
    });

    return (
        <>
            <Drawer
                open={visible}
                title="Add new a Direct Order"
                placement='left'
                onClose={onCancel}
                width={1200}
                destroyOnClose
                extra={
                    <Space>
                        <Button
                            type="default"
                            onClick={() => {
                                form.resetFields();
                                setTotals(initialTotal);
                            }}>
                            Reset
                        </Button>
                        <Button
                            type="primary"
                            loading={loadingObj.sendLoading}
                            onClick={() => {
                                setLoadingObj(prevState => ({ ...prevState, sendLoading: true }));
                                form
                                    .validateFields()
                                    .then(async (values) => {
                                        values.totals = totals;
                                        const fileResponse = await DirectOrdersApi.createOrder(baseUrl, values);
                                        await setOrderDetails(fileResponse.data)
                                        setLoadingObj(prevState => ({ ...prevState, sendLoading: false }));
                                        message.success(fileResponse.success);
                                        setKey(prevKey => prevKey + 1);
                                        onCancel();
                                        form.resetFields();
                                        setTotals(initialTotal);

                                        // Wait for two seconds before printing the receipt
                                        setTimeout(() => {
                                            handlePrint();
                                        }, 1000);
                                    })
                                    .catch((info) => {
                                        setLoadingObj(prevState => ({ ...prevState, sendLoading: false }));
                                    });
                            }}>
                            Save
                        </Button>
                    </Space>
                }
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            name="form_in_modal"
                            initialValues={{
                                devices: [{ qty: 1, amount: 0 }],
                                deposit_amount: 0,
                            }}
                            onValuesChange={processedFieldsChange}
                        >
                            <Row gutter={16} className='d-block'>
                                <Col span={12}>
                                    <Form.Item name="customer" label="Customer Name:" rules={
                                        [
                                            {
                                                required: true,
                                                message: 'Please input customer name!',
                                            },
                                        ]
                                    }>
                                        <Input placeholder="Customer Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="phone_number" label="Phone Number:" >
                                        <Input placeholder="Phone Number" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="email" label="Email:"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not a valid email!',
                                            },
                                        ]} >
                                        <Input placeholder="Email" type='email' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>

                                <Col span={24}>
                                    <Form.List name="devices">
                                        {(fields, { add, remove }) => (
                                            <>

                                                {fields.map(({ key, name, ...restField }) => (
                                                    <>
                                                        <Row gutter={24} key={key} className='pt-2'>
                                                            <Col span={4}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'model']}
                                                                    label={`Model ${key + 1}`}
                                                                    rules={
                                                                        [
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing Model Name',
                                                                            },
                                                                        ]}
                                                                >
                                                                    <Input placeholder="Model Name" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={3}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'imei_no']}
                                                                    label="Imei No"
                                                                >
                                                                    <Input placeholder="Imei No" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={3} className='d-none'>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'qty']}
                                                                    label="Qty"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing qty',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputNumber size="large" min={1} placeholder="Qty" />
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span={4}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'fault_note']}
                                                                    label="Service Required"
                                                                    className='mb-0'
                                                                >
                                                                    <Input.TextArea placeholder="Service Required" rows={3} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'password']}
                                                                    label="Password"
                                                                >
                                                                    <Input placeholder="Password" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={5}>
                                                                <Form.Item {...restField} name={[name, 'collection_time']} label="Collection Time">
                                                                    <DatePicker
                                                                        showTime={{
                                                                            format: 'HH:mm',
                                                                            minuteStep: 15,
                                                                        }}
                                                                        format="YYYY-MM-DD HH:mm"
                                                                        disabledDate={(current) => {
                                                                            const today = dayjs().startOf('day');
                                                                            return current && (current < today || current.day() === 0);
                                                                        }}
                                                                        placeholder="Select Date & Time"
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={3}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'amount']}
                                                                    label="Amount"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing amount',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputNumber
                                                                        size="large"
                                                                        className='w-100'
                                                                        placeholder="Amount"
                                                                        min={0}
                                                                        formatter={(value) => `£${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={1} className='d-flex align-center justify-start'>
                                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                                            </Col>
                                                            <Col span={7} >
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'note']}
                                                                    label="Note" >
                                                                    <Input.TextArea placeholder="Note" rows={3} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ))}

                                                <Form.Item label="">
                                                    <Button
                                                        size='middle'
                                                        shape="round"
                                                        type="dashed"
                                                        onClick={() => add({ service: '', amount: 0, qty: 1, note: '' })}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={16}>
                                        <Col span={18}>
                                        </Col>

                                        <Col span={6}>
                                            <Form.Item
                                                name="deposit_amount"
                                                label="Deposit Amount"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input Deposit Amount!',
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    className='w-100'
                                                    disabled={totals.netTotal === 0 ? true : false}
                                                    placeholder="Deposit Amount"
                                                    min={0}
                                                    formatter={(value) => `£${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                />
                                            </Form.Item>

                                            <List
                                                size="small"
                                                header={<Text>Invoice Summery</Text>}
                                                bordered
                                                dataSource={[
                                                    <ListItem key={1} keyText="Repair Total : " valueText={'£' + totals.orderTotal.toFixed(2)} />,
                                                    <ListItem key={2} keyText="Deposit Amount : " valueText={'£' + totals.deposit_amount.toFixed(2)} />,
                                                    <ListItem key={3} keyText="Due Amount : " valueText={'£' + totals.balance_amount.toFixed(2)} />,
                                                ]}
                                                renderItem={item => <List.Item>{item}</List.Item>}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Drawer>

            <div className="invisible position-absolute">
                <Receipt
                    ref={receiptRef}
                    activeOrder={orderDetails}
                />
            </div>
        </>
    );
};

const AddNewDirectOrder = ({ setKey }) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <Button
                type="primary"
                ghost
                onClick={() => setOpen(true)}
            >
                Add +
            </Button>
            <CollectionCreateForm
                visible={open}
                setKey={setKey}
                onCancel={() => {
                    setOpen(false);
                }}
            />
        </div>
    );
};
export default AddNewDirectOrder;