import React, { useState } from 'react';
import { Drawer, List } from 'antd';
import { FieldTimeOutlined, HddOutlined, HistoryOutlined } from '@ant-design/icons';
import { MessageOutlined } from '@ant-design/icons';

import styles from '../../Orders.module.scss';

import WFCNoteUpdate from '../WFCNoteUpdate';
import ReturnItems from './ReturnItems';
import OrderEvidence from '../../../../components/OrderEvidence';
import SendMessage from '../../../../components/SendMessage';

const dataSourceList = [
    {
        id: 1,
        name: 'Order Evidence',
        alias: 'orderEvidence',
        icon: (<HddOutlined className={styles.iconAlt} />),
        width: 1000
    },
    {
        id: 2,
        name: 'WFC Note',
        alias: 'wfcNote',
        icon: (<FieldTimeOutlined className={styles.iconAlt} />),
        width: 900
    },
    {
        id: 3,
        name: 'Send SMS',
        alias: 'sendSMS',
        icon: (<MessageOutlined className={styles.iconAlt} />),
        width: 900
    },
    {
        id: 4,
        name: 'Returns',
        alias: 'returns',
        icon: (<HistoryOutlined className={styles.iconAlt} />),
        width: 800
    },
];

export default function DrawerWrapper({ activeOrder, baseUrl, handleReset, employees, notificationTemplate }) {
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const showDrawer = (item) => {
        setSelectedItem(item);
        setOpen(true);
    };

    const onClose = () => {
        setSelectedItem(null);
        setOpen(false);
    };

    return (
        <>
            <List
                className='mb-4'
                grid={{ gutter: 24, column: activeOrder.return_request?.length > 0 ? 5 : 4 }}
                size="small"
                dataSource={dataSourceList}
                bordered
                renderItem={(item) => {
                    if (item.alias === 'returns' && !activeOrder.return_request?.length > 0) return;
                    return (
                        <List.Item
                            className='py-2 mb-0 cursor-pointer'
                            key={item.id}
                            onClick={() => showDrawer(item.alias)}
                        >
                            <List.Item.Meta
                                avatar={item.icon}
                                title={item.name}
                            />
                        </List.Item>
                    );
                }}
            />
            <Drawer
                width={dataSourceList.find(item => item.alias === selectedItem)?.width || 640}
                size='large'
                placement="left"
                onClose={onClose}
                open={open}
                title={dataSourceList.find(item => item.alias === selectedItem)?.name}
            >
                {selectedItem === 'orderEvidence' &&
                    <OrderEvidence
                        id={activeOrder?.id}
                        baseUrl={baseUrl}
                    />
                }

                {selectedItem === 'wfcNote' &&
                    <WFCNoteUpdate
                        key={activeOrder.id + '__EditModel'}
                        orderId={activeOrder.id}
                        employees={employees}
                        baseUrl={baseUrl}
                        handleReset={handleReset}
                    />
                }

                {selectedItem === 'sendSMS' &&
                    <SendMessage
                        activeOrder={activeOrder}
                        baseUrl={baseUrl}
                        orderType="direct"
                        onClose={onClose}
                    />
                }

                {/* keep this as last item */}
                {selectedItem === 'returns' &&
                    <ReturnItems
                        activeOrder={activeOrder}
                    />
                }
            </Drawer>
        </>
    );
}
