import appApi from "../index";

const endpoint = "sms";

const sendMessage = (url = null, phone, message, type, orderId) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .post(`/${endpoint}/send-message`, { phone, message, type, orderId })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
}

const getSMSPricing = (url = null) => {
    return new Promise((resolve, reject) => {
        appApi(url)
            .get(`/${endpoint}/pricing`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data);
                } else if (error.request) {
                    reject(error);
                } else {
                    reject(error);
                }
            });
    });
}

export default {
    sendMessage,
    getSMSPricing
};