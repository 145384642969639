import { Button } from "antd";
import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { QRCodeSVG } from "qrcode.react";
import { PhoneOutlined, WhatsAppOutlined, PrinterOutlined } from "@ant-design/icons";
import { render } from "react-thermal-printer";
import dayjs from 'dayjs';

import styles from "./ReceiptPrint.module.scss";

import ThermalReceipt from "./ThermalReceipt";

import logo from "../../assets/images/site-logo.png";

export const Receipt = React.forwardRef(({ activeOrder }, ref) => {
  Receipt.displayName = 'Receipt';
  return (
    <div
      ref={ref}
      className={styles.printContainer}
    >
      <div className="text-center">
        <img
          src={logo}
          alt="logo"
          width='150px'
          className={[styles.logo, 'mb-1'].join(' ')}
        />
      </div>
      <h4 className="text-center">Fixmybrokenscreen.Co.Uk LTD</h4>
      <p className="text-center mb-1">
        23 Whitechapel Road, London, E1 1DU.
      </p>

      <div className="d-flex justify-between items-center flex-wrap mb-2">
        <p className="text-center"><PhoneOutlined /> 020 8064 2362</p>
        <p className="text-center"><WhatsAppOutlined /> 078 288 03645</p>
      </div>

      <div className={styles.smallText}>
        <div className="d-flex justify-between items-center flex-wrap">
          <p className="mb-1">Order No. {activeOrder?.order_no}</p>
          <p className="mb-1">Date: {new Date(activeOrder?.created_at).toLocaleDateString()}</p>
        </div>
        <p className="mb-1">Customer: {activeOrder?.user?.customer_name}</p>
      </div>
      <div className={styles.itemBorder}></div>

      {activeOrder?.items.map((item, index) => (
        <div key={index} >
          <p>{item?.model_name}</p>
          <div className="d-flex justify-between align-start">
            <p>{item?.fault_note || item?.quantity + ' X ' + parseFloat(item?.unit_price).toFixed(2)}</p>
            <p>£{(parseFloat(item?.quantity) * parseFloat(item?.unit_price)).toFixed(2)}</p>
          </div>

          {item?.est_collection_time &&
            <div className={[styles.smallText, 'd-flex justify-start'].join(' ')}>
              <p className="pr-1">Collection Time:</p>
              <p>  {dayjs(item.est_collection_time).format("DD-MM-YYYY hh:mm A")}</p>
            </div>
          }

          {item?.note &&
            <div className={[styles.smallText, 'd-flex justify-start'].join(' ')}>
              <p className="pr-1">Note:</p>
              <p>{item.note}</p>
            </div>
          }
          <div className={styles.itemBorder}></div>
        </div>
      ))}

      <div className="d-flex justify-between">
        <p>Total:</p>
        <p>£{activeOrder?.repair_cost}</p>
      </div>
      <div className="d-flex justify-between">
        <p>Deposit Amount:</p>
        <p>£{activeOrder?.deposit_amount}</p>
      </div>
      <div className="d-flex justify-between">
        <p>Due Balance:</p>
        <p>£{activeOrder?.due_amount}</p>
      </div>
      <hr />

      <p className="text-center mt-4">Thank you for choosing us. <br /><span className="font-semibold">Fixmybrokenscreen.Co.Uk</span></p>
      <div className="text-center my-3">
        <QRCodeSVG value={activeOrder?.order_no} size={45} />
      </div>
    </div>
  );
});

const ReceiptPrint = ({ activeOrder, btnEnable }) => {
  const receiptRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  // State for the USB device
  const [usbDevice, setUsbDevice] = useState(null);

  // Helper function to set up the USB device
  const setupUsb = async (device) => {
    await device.open();
    // If no configuration is selected, choose configuration #1
    if (device.configuration === null) {
      await device.selectConfiguration(1);
    }
    await device.claimInterface(0);
  };

  // Function to print via WebUSB
  const printViaUSB = async () => {
    // Check if WebUSB is supported
    if (!navigator.usb) {
      console.error('WebUSB is not supported in this browser or environment');
      alert(
        'WebUSB is not supported in this browser. Try using Chrome or Edge on desktop.'
      );
      return;
    }

    let device = usbDevice;
    if (!device) {
      try {
        // Request a USB device with the specified vendorId
        device = await navigator.usb.requestDevice({
          filters: [
            { vendorId: 1046 },
            { vendorId: 0x04b8 }, // Epson
            { vendorId: 0x0483 }, // Some thermal printers
            { vendorId: 0x0416 }, // VendorID for some POS printers
            { vendorId: 0x1fc9 }, // VendorID for some POS printers
            { vendorId: device?.vendorId }
          ]
        });
        console.log('USB device selected:', device);
        await setupUsb(device);
        setUsbDevice(device);
      } catch (error) {
        console.error('USB device selection/setup failed:', error);
        alert('Unable to select a printer device. Please try again.');
        return;
      }
    }

    try {
      // Check if device is still connected
      if (device.opened) {
        const data = await render(<ThermalReceipt activeOrder={activeOrder} />);
        await device.transferOut(1, data);
        console.log('Print data sent successfully via USB');
      } else {
        console.log('Device connection lost, attempting to reconnect...');
        await setupUsb(device);
        const data = await render(<ThermalReceipt activeOrder={activeOrder} />);
        await device.transferOut(1, data);
      }
    } catch (error) {
      console.error('USB printing failed:', error);
      alert(
        'Failed to print. Please check your printer connection and try again.'
      );
    }
  }

  return (
    <>
      {btnEnable &&
        <>
          <div>
            <Button
              type="primary"
              className="mr-1"
              onClick={handlePrint}
            >
              <PrinterOutlined /> Receipt
            </Button>
            <Button variant="solid" onClick={printViaUSB}>
              <PrinterOutlined />   Print via USB
            </Button>

          </div>
          <div className="invisible position-absolute">
            <Receipt ref={receiptRef} activeOrder={activeOrder} />
          </div>
        </>
      }
    </>
  );
};

export default ReceiptPrint;