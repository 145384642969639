import { LoadingOutlined, SearchOutlined, SyncOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Flex,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
  message,
  Modal,
  Divider
} from "antd";
import qs from 'qs';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Highlighter from 'react-highlight-words';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import styles from './Orders.module.scss';

import NotificationTemplateApi from "../../apis/order-management/NotificationTemplateApi";
import OrdersApi from "../../apis/order-management/repair-order/OrdersApi";
import useDocumentTitle from "../../hooks/useDocumentTitle";
// import DateRangeFilter from "../../components/Dropdown/DateRangeFilterAlt";
import EmployeeApi from '../../apis/EmployeeApi';
import ServiceApi from '../../apis/order-management/ServiceApi';
import { ConfirmModal } from '../../components/ConfirmModal';
import PayTypeBadge from '../../components/PayTypeBadge';
import StatusBadge from '../../components/StatusBadge';
import { SiteOptions, dropdownConditionals } from '../../components/layout/util/common';
import { getBaseUrlByOrderId } from '../../helpers/getBaseUrlByOrderId';
import useLogin from '../../hooks/useLogin';
import DeviceChecker from './Components/DeviceChecker';
import EditableCell from './Components/Editable/EditableCell';
import EditableRow from './Components/Editable/EditableRow';
import ExpandedRowRender from "./Components/ExpandedRowRender";
import QuickEdit from "./Components/QuickEdit";
import SelectedOptions from "./Components/SelectedOptions";
import { filteredOrderStatus, getAllOrderStatus, getAllPayStatus, getAllPayType, getAllWFCStatus, getOrderStatusByValue, getPayStatusByValue, getWFCStatusByValue } from "./util/common";
import useUrgentOrderCounts from '../../hooks/useSideMenuBadgeCount';
import Basket from '../../components/Basket';
import TableRowDetails from './Components/TableRowDetails';
import useSideMenuBadgeCount from '../../hooks/useSideMenuBadgeCount';
import useExport from '../../hooks/useExport';
import Icon from '../../components/Icon';
import { PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import { FaFileCsv, FaFilePdf } from 'react-icons/fa';

dayjs.extend(isBetween);

const { Text } = Typography;
const { confirm } = Modal;

const LoadingIcon = <LoadingOutlined style={{ fontSize: '2rem' }} spin />;

export default function Orders(props) {
  useDocumentTitle(props.title);

  const { roles } = useSelector((state) => state.auth.user[0])
  const baseUrl = useSelector((state) => state.auth.base_url)

  let { pathname } = useLocation();
  const [searchableOrderId, setSearchableOrderId] = useState('');

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [key, setKey] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    query: "",
    status: null,
    wfc_status: null,
    is_paid: null,
    is_urgent: null,
    is_collection: null,
    category_id: null,
    product_id: null,
    product_name: null,
  });

  const queryRef = useRef('');
  const [query, setQuery] = useState('');
  const [orderIdSearch, setOrderIdSearch] = useState('');
  const tradeBaseUrl = SiteOptions[5].value;

  const [notificationTemplate, setNotificationTemplate] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [services, setServices] = useState(null);
  const [orderStatusCounts, setOrderStatusCounts] = useState(null);
  const [visibleBulkAction, setVisibleBulkAction] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef('');

  const [selectedValues, setSelectedValues] = useState({
    selectedRowKeys: [],
    batchRecord: []
  });

  const { counts, mutateCountKey } = useSideMenuBadgeCount();

  const [visible, setVisible] = useState(false);
  const [visibleDeviceChecker, setVisibleDeviceChecker] = useState(false);
  const [activeOrderId, setActiveOrderId] = useState(null);

  const rowSelection = {
    selectedRowKeys: selectedValues.selectedRowKeys,
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => setSelectedValues({ selectedRowKeys, batchRecord: selectedRows }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE
    ]
  };

  const { handleChangeLogin } = useLogin(setLoading);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetSearch = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetSearch(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => { if (record[dataIndex]) return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) },
    filterDropdownProps: {
      onOpenChange: (open) => {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const defaultColumns = [
    {
      title: "Order #",
      dataIndex: "order_no",
      key: "order_no",
      render: (text, record) => (
        <TableRowDetails
          id={record.id}
          baseUrl={baseUrl}
        >
          {text}
        </TableRowDetails>
      ),
      sorter: (a, b) => a.order_no > b.order_no,
      onFilter: (value, { order_no }) => (value === order_no),
      // ...getColumnSearchProps('order_no'), // This is not needed as we are using custom search
    },
    {
      title: "Created At",
      key: "created_at",
      dataIndex: "created_at",
      render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")}</Text> : "-"),
      sorter: (a, b) => dayjs(a.created_at) - dayjs(b.created_at),
    },
    {
      title: "Arrived At",
      key: "date_of_arrival",
      dataIndex: "date_of_arrival",
      render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")}</Text> : "-"),
      sorter: (a, b) => dayjs(a.date_of_arrival) - dayjs(b.date_of_arrival),
      // filterDropdown: (props) => (<DateRangeFilter
      //   {...props}
      //   setSearchText={setSearchText}
      //   setSearchedColumn={setSearchedColumn}
      //   dataIndex="created_at"
      // />),
      // onFilter: (value, { created_at }) => {
      //   if (created_at) {
      //     const date = dayjs(created_at);
      //     const [from, to] = value;
      //     if (!from || !to) return true;
      //     return date.isBetween(dayjs(from).format("DD-MM-YYYY"), dayjs(to).format("DD-MM-YYYY"), 'day', '[]');
      //   }
      //   else
      //     return false;
      // }
    },
    {
      title: "Posted At",
      key: "delivered_date",
      dataIndex: "delivered_date",
      render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")}</Text> : "-"),
      sorter: (a, b) => dayjs(a.date_of_delivery) - dayjs(b.date_of_delivery),
    },
    {
      title: "Est. Delivery",
      key: "est_delivery_date",
      dataIndex: "est_delivery_date",
      render: text => (text ? <Text type="secondary" title={dayjs(text).format('DD-MM-YYYY hh:mm A')}>{dayjs(text).format("DD-MM-YYYY hh:mm A")}</Text> : "-"),
      sorter: (a, b) => dayjs(a.est_delivery_date) - dayjs(b.est_delivery_date),
    },
    {
      title: "Full Name",
      key: "full_name",
      dataIndex: "full_name",
      ...getColumnSearchProps('full_name'),
      onFilter: (value, { first_name, last_name }) => {
        let fullName = `${first_name} ${last_name ? last_name : ""}`;
        if (fullName) return fullName.toString().toLowerCase().includes(value.toLowerCase())
      },
      filterDropdownProps: {
        onOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        }
      },
      render: (_, { first_name, last_name }) => {
        let fullName = `${first_name} ${last_name ? last_name : ""}`;
        return (searchedColumn === 'full_name' ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={fullName ? fullName.toString() : ''}
          />
        ) : (
          fullName
        ))
      },
    },
    {
      title: "Postcode",
      key: "postal_code",
      dataIndex: "postal_code",
      ...getColumnSearchProps('postal_code'),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (value) => {
        const statusObj = getOrderStatusByValue(value);
        return (<StatusBadge value={value} color={statusObj?.status} label={statusObj?.label} />);
      },
      filters: (getAllOrderStatus.slice(1)).map((item) => ({ text: item.label, value: item.value })),
      onFilter: (value, record) => (value === record.status),
      editable: true,
    },
    {
      title: "WFC",
      key: "wfc_status",
      dataIndex: "wfc_status",
      render: (value) => {
        const statusObj = getWFCStatusByValue(value);
        return (<StatusBadge value={value} color={statusObj?.status} label={statusObj?.label} />);
      },
      filters: (getAllWFCStatus.slice(1)).map((item) => ({ text: item.label, value: item.value })),
      onFilter: (value, record) => (value === record.wfc_status),
      editable: true,
    },
    {
      title: "Pay Status",
      key: "is_paid",
      dataIndex: "is_paid",
      render: (value, record) => {
        const statusObj = getPayStatusByValue(value);
        return (
          <StatusBadge
            value={value}
            color={statusObj?.color}
            label={statusObj?.label}
            isCollection={record?.is_collection}
            isCollectionArranged={record?.is_collection && record?.is_collection_arranged}
          />
        );
      },
      filters: getAllPayStatus.map((item) => ({ text: item.label, value: item.value })),
      onFilter: (value, record) => (value === record.is_paid),
    },
    {
      title: "Pay Type",
      key: "payment_type",
      dataIndex: "payment_type",
      render: (value) => (<PayTypeBadge type={value} />),
      filters: getAllPayType.map((item) => ({ text: item.label, value: item.value })),
      onFilter: (value, record) => (value === record.payment_type),
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productNameFilter, setProductNameFilter] = useState(null);

  const onChange = (e) => {
    // reset the orderIdSearch, query
    handleResetFilter(true);
    setSelectedProduct(null);
    setSelectedCategory(null);
    setProductNameFilter(null);

    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: { ...prevTableParams.pagination },
      query: "",
      [e.target.name]: e.target.value
    }));
    setKey(prevState => prevState + 1);
  };

  const onChangeCategoryFilter = (value) => {
    setSelectedCategory(value);
    setSelectedProduct(null);
    setProductNameFilter(null);
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: { ...prevTableParams.pagination },
      is_urgent: null,
      is_collection: null,
      category_id: value,
      product_id: null,
      product_name: null
    }));
  };

  const onChangeProductFilter = (value) => {
    setSelectedProduct(value);
    setProductNameFilter(null);
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: { ...prevTableParams.pagination },
      is_urgent: null,
      is_collection: null,
      product_id: value,
      product_name: null
    }));
  };

  const onChangeOrders = (e) => {
    const selectedValue = e.target.value;

    // to clear the search input of query
    if (query !== "") setQuery('');

    // to clear the search input of orderIdSearch
    if (orderIdSearch !== "") setOrderIdSearch('');

    // to re-render the orderId search
    if (searchableOrderId !== "") setSearchableOrderId("");

    setSelectedProduct(null);
    setSelectedCategory(null);
    setProductNameFilter(null);

    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: { ...prevTableParams.pagination },
      query: "",
      category_id: null,
      product_id: null,
      product_name: null,
      is_urgent: selectedValue === 'urgentOrders' ? true : null,
      is_collection: selectedValue === 'collectionOrders' ? true : null
    }));
  };

  useLayoutEffect(() => {
    let isCancelled = false
    mountData(isCancelled);

    return () => {
      isCancelled = true
    }
  }, []);

  useLayoutEffect(() => {
    let isCancelled = false;

    fetchOrderStatusCount(isCancelled);

    return () => {
      isCancelled = true;
    };
  }, [key]);

  const getQueryParams = (params) => ({
    current_page: params.pagination?.current,
    per_page: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const [refreshKey, setRefreshKey] = useState(0);
  useUrgentOrderCounts(baseUrl, refreshKey);
  const fetchOrders = useCallback((isCancelled) => {
    setLoading(true);
    OrdersApi.list(baseUrl, qs.stringify(getQueryParams(tableParams)))
      .then(({ data: orderData }) => {
        if (!isCancelled) {
          setOrders(orderData.data);
          setTableParams((prevTableParams) => ({
            ...prevTableParams,
            query: getValidSearchText(tableParams.query, searchableOrderId),
            pagination: {
              ...prevTableParams.pagination,
              current: orderData.current_page,
              pageSize: orderData.per_page,
              total: orderData.total,
            },
          }));
          setLoading(false);
          setRefreshKey(prevKey => prevKey + 1);
        }
      })
      .catch(err => {
        if (!isCancelled)
          setLoading(false);
      });
  }, [baseUrl, tableParams, searchableOrderId, getQueryParams]);

  function getValidSearchText(query, searchText) {
    const searchBoxText = queryRef.current?.input?.value;
    if (searchText === "") {
      if (searchBoxText === "") return "";
      return query;
    }
    return searchText;
  }

  useEffect(() => {
    let isCancelled = false
    fetchOrders(isCancelled);

    return () => {
      isCancelled = true
    }
  }, [key, JSON.stringify(tableParams), searchableOrderId]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setOrders([]);
    }
  };

  const showModal = (id) => {
    setActiveOrderId(id);
    setVisible(true);
  };

  const showDeviceCheckerModal = (id) => {
    setActiveOrderId(id);
    setVisibleDeviceChecker(true);
  };

  const handleReset = () => {
    setActiveOrderId(null);
    setVisible(false);
    setKey(prevState => prevState + 1);
  }

  const handleCancel = () => {
    setVisible(false);
    setActiveOrderId(null);
  };

  const handleHide = () => {
    setVisible(false);
    setVisibleBulkAction(true);
  };

  const handleCancelDeviceChecker = () => {
    setActiveOrderId(null);
    setVisibleDeviceChecker(false);
  };

  const search = value => {
    setSearchableOrderId('');

    setSelectedCategory(null);
    setSelectedProduct(null);
    setProductNameFilter(null);

    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: { ...prevTableParams.pagination },
      is_urgent: null,
      is_collection: null,
      query: value,
      wfc_status: null,
      status: null,
      is_paid: null,
      category_id: null,
      product_id: null,
      product_name: null
    }));
  };

  const rowsUnCheckHandler = () => {
    setSelectedValues({
      selectedRowKeys: [],
      batchRecord: []
    })
  }

  const mountData = useCallback(async (isCancelled) => {
    try {
      setTableParams(prevTableParams => ({
        ...prevTableParams,
        is_urgent: null,
        is_collection: null,
      }));

      const [notificationTemplateResponse, serviceListResponse, employeeListResponse] = await Promise.all([
        NotificationTemplateApi.getAll(baseUrl, 'order'),
        ServiceApi.getServices(baseUrl),
        EmployeeApi.list(baseUrl)
      ]);

      if (!isCancelled) {
        if (notificationTemplateResponse.data) setNotificationTemplate(notificationTemplateResponse.data);
        if (serviceListResponse) setServices(serviceListResponse);
        if (employeeListResponse) setEmployees(employeeListResponse);
      }
    } catch (error) {
      console.error("Error mounting data:", error);
    }
  }, [baseUrl, pathname]);

  const fetchOrderStatusCount = async (isCancelled) => {
    const statusCounts = await OrdersApi.getStatusCounts(baseUrl, qs.stringify(getQueryParams(tableParams)))
    if (!isCancelled) {
      mutateCountKey();

      if (statusCounts) {
        setOrderStatusCounts(statusCounts);
      }
    }
  }

  // Table - Editable Cell
  const handleSaveOrderStatus = async ({ id, status, order_no }) => {
    setLoading(true);
    try {
      if (!id)
        throw new Error('Something went wrong!');

      let sendDiagnosticEmail = false;
      if (status === 2) {
        if (orders.find(order => order.id === id).payment_type === 0) {
          try {
            await ConfirmModal({
              title: 'Diagnostic Email Confirmation',
              content: 'Do you want to send the diagnostic email to the customer?',
              okText: 'Send Diagnostic Email',
              cancelText: 'Send Device Received Email',
              onOk: async () => {
                sendDiagnosticEmail = true;
              },
              onCancel: () => {
                //
              }
            });
          } catch (error) {
            // Handle rejection (if 'No' is selected)
          }
        }
      }

      if (status === 2 || status === 9) {
        showDeviceCheckerModal(id);
      }

      let isGenerateLabel = false;
      if (status === 3 || status === 11) {
        try {
          await ConfirmModal({
            title: 'Royal mail shipment label',
            content: 'Do you want to generate the label for royal mail shipment?',
            onOk: async () => {
              isGenerateLabel = true;
            },
            onCancel: () => {
              //
            }
          });
        } catch (error) {
          // Handle rejection (if 'No' is selected)
        }
      }

      const updatedOrder = await OrdersApi.updateOne(baseUrl, id, {
        status: status,
        is_active: 1,
        order_type: 'repair',
        send_diagnostic_email: sendDiagnosticEmail ? 1 : 0
      });

      if (updatedOrder) {
        message.success(`${order_no} - Order is updated!`);
        setKey(prevState => prevState + 1);
        setLoading(false);

        // when change 'complete' order status, should be trigger royal mail shipment label.
        if (status === 3 && isGenerateLabel) {
          const hide = message.loading('Shipment label is generating..', 0);
          const response = await OrdersApi.royalMailOrder(baseUrl, id);
          hide();
          if (response) {
            message.success('Order was exported to Royal Mail!');
          }
        }
      }
    } catch (e) {
      message.error(e.message ?? 'Something went wrong!');
      setLoading(false);
    }
  }

  const handleSaveWFCStatus = async ({ id, wfc_status, order_no }) => {
    setLoading(true);

    let sendNotificationForWFCPending = false;
    const confirmSendNotification = () => {
      return new Promise((resolve) => {
        confirm({
          title: 'Do you want to send a sms notification to the customer?',
          content: 'This will send a sms notification to the customer.',
          icon: <ExclamationCircleOutlined />,
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            resolve(true);
          },
          onCancel() {
            resolve(false);
          },
        });
      });
    };

    try {
      // wait for the user to confirm the action for send sms notification when wfc_status is set to '2'
      if (wfc_status == 2) {
        sendNotificationForWFCPending = await confirmSendNotification();
      }

      const formData = new FormData();
      formData.append('wfc_status', wfc_status);
      formData.append('type', 'repair');
      formData.append('send_notification', sendNotificationForWFCPending);

      const wfcUpdated = await NotificationTemplateApi.wfcUpdate(baseUrl, id, formData);
      message.success(`${order_no} - ${wfcUpdated.message}!`);
      setKey(prevState => prevState + 1);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record) => ({
          onClick: (_) => {
            showModal(record.id)
          }
        })
      };
    }

    if (col.dataIndex === 'wfc_status') {
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSaveWFCStatus,
        }),
      };
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveOrderStatus,
      }),
    };
  });

  const ignoredColumns = [
    'wfc_status',
    'is_paid',
    'payment_type'
  ];
  const { onExcelPrint, onCsvPrint, onPdfPrint } = useExport({
    columns: columns.filter(col => !ignoredColumns.includes(col.dataIndex)),
    data: orders,
    fileName: "Orders_Report",
    pdfTheme: "striped",
  });

  const handleBaseUrlChange = async (value) => {
    // Reset the filters completely
    handleResetFilter(true);

    const result = await handleChangeLogin(value);
    if (result.success) {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
      setKey(prevKey => prevKey + 1)
    }
    else {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
    }
  };

  const onSearchHandler = async (orderId) => {
    handleResetFilter(true);

    if (orderId === "") {
      setSearchableOrderId("");
      return;
    }

    const nextBaseUrl = await getBaseUrlByOrderId(baseUrl, orderId)
    if (nextBaseUrl === baseUrl) {
      setSearchableOrderId(orderId);
      return;
    }
    await handleBaseUrlChange(nextBaseUrl);
    setSearchableOrderId(orderId);
  }

  const handleResetFilter = (isCompleteReset = false) => {
    // to clear the search input of query
    if (query !== "") setQuery('');

    // to clear the search input of orderIdSearch
    if (orderIdSearch !== "") setOrderIdSearch('');

    // to re-render the orderId search
    if (searchableOrderId !== "") setSearchableOrderId("");

    setSelectedProduct(null);
    setSelectedCategory(null);
    setProductNameFilter(null);

    // to re-render the query search
    if (tableParams.query !== "" | tableParams.status !== null | tableParams.wfc_status !== null | tableParams.is_urgent !== null | tableParams.is_collection !== null)
      setTableParams(prevTableParams => ({
        ...prevTableParams,
        query: "",
        is_urgent: null,
        is_collection: null,
        status: isCompleteReset ? null : prevTableParams.status,
        wfc_status: isCompleteReset ? null : prevTableParams.wfc_status,
        category_id: null,
        product_id: null,
        product_name: null
      }));
  }

  const optionsOrder = useMemo(() => [
    {
      label: (
        <span>All</span>
      ),
      value: 'all',
    },
    {
      label: (
        <Flex>
          <span>Urgent Orders</span>
          {counts?.urgentOrder > 0 && (
            <Badge
              count={counts.urgentOrder}
              style={{
                marginLeft: '3px',
              }}
            />
          )}
        </Flex>
      ),
      value: 'urgentOrders',
    },
    {
      label: (
        <Flex>
          <span>Collection Orders</span>
          {counts?.collectionOrder > 0 && (
            <Badge
              count={counts.collectionOrder}
              style={{
                marginLeft: '6px',
              }}
            />
          )}
        </Flex>
      ),
      value: 'collectionOrders',
    },
  ], [counts]);

  const handleRefresh = () => {
    // to clear the search input of query
    if (query !== "") setQuery('');

    // to clear the search input of orderIdSearch
    if (orderIdSearch !== "") setOrderIdSearch('');

    // to re-render the orderId search
    if (searchableOrderId !== "") setSearchableOrderId("");

    setSelectedProduct(null);
    setSelectedCategory(null);
    setProductNameFilter(null);

    setKey(prevKey => prevKey + 1);
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        current: 1,
        pageSize: 100,
      },
      query: "",
      status: null,
      wfc_status: null,
      is_paid: null,
      is_urgent: null,
      is_collection: null,
      category_id: null,
      product_id: null,
      product_name: null,
    }));
  };

  return (
    <div className='bg-white py-4 px-4 rounded'>
      <Row align="top">
        <Col md={9} className='d-inline-flex'>
          <Button
            icon={<SyncOutlined />}
            loading={loading}
            className="mr-2 px-2"
            onClick={handleRefresh}
          />

          <Input.Search
            placeholder="Find by any order id.."
            title='Find by any order id..'
            prefix={<SearchOutlined />}
            allowClear
            onSearch={onSearchHandler}
            className={`${styles.search} mr-4`}
            disabled={loading}
            style={{ width: 320 }}
            onChange={(e) => setOrderIdSearch(e.target.value)}
            value={orderIdSearch}
          />
        </Col>

        <Col md={15}>
          <Flex
            style={{
              whiteSpace: 'nowrap',
            }}
            className='d-flex'
            justify='end'
          >
            {dropdownConditionals.some(page => pathname === page) &&
              <Select
                value={baseUrl}
                onChange={handleBaseUrlChange}
                className="mr-4"
                style={{ width: 260 }}
                options={SiteOptions.filter(option => option.id !== 2)}
                disabled={loading}
              />
            }

            <Input.Search
              ref={queryRef}
              placeholder="Search by Order Id, Created At, Name, E-Mail, Ph. No, Postcode"
              title='Search by Order Id, Created At, Name, E-Mail, Ph. No, Postcode'
              className={`${styles.search} mr-4`}
              onSearch={search}
              allowClear
              disabled={loading}
              style={{ width: 500 }}
              onChange={(e) => setQuery(e.target.value)}
              value={query}
            />
            <Radio.Group
              size="small"
              onChange={onChangeOrders}
              value={
                tableParams.is_urgent
                  ? 'urgentOrders'
                  : tableParams.is_collection
                    ? 'collectionOrders'
                    : 'all'
              }
              block
              options={
                baseUrl === tradeBaseUrl
                  ? optionsOrder.filter(option => option.value !== 'collectionOrders')
                  : optionsOrder
              }
              defaultValue='all'
              optionType="button"
              buttonStyle="solid"
            />
          </Flex>
        </Col>

        <Col span={24}>
          <Row className='mt-2'>
            <Col md={12}>
              <Radio.Group
                size='small'
                onChange={onChange}
                defaultValue="null"
                value={tableParams.status}
                name='status'
                id='status'
                disabled={loading}
              >
                {filteredOrderStatus.map((item, idx) => (
                  <Radio.Button key={idx} value={item.value ?? ''}>
                    <Badge
                      color={item.color}
                      text={item.label + ` ${orderStatusCounts?.statusCounts?.[item.value] !== undefined ? "(" + orderStatusCounts.statusCounts[item.value] + ")" : ""}`}
                      className={styles.font_weight_normal}
                    />
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Col>

            <Col md={12} className='text-right d-flex items-start justify-end'>
              <Radio.Group
                size='small'
                onChange={onChange}
                defaultValue="null"
                value={tableParams.wfc_status}
                name='wfc_status'
                id='wfc_status'
                className="float-right"
                disabled={loading}
              >
                {getAllWFCStatus.filter(item => item.value !== 5).map((item, idx) => (
                  <Radio.Button key={idx} value={item.value ?? ''}>
                    <Badge
                      color={item.color}
                      text={item.label + ` ${orderStatusCounts?.wfcStatusCounts?.[item.value] !== undefined ? "(" + orderStatusCounts.wfcStatusCounts[item.value] + ")" : ""}`}
                      className={styles.font_weight_normal}
                    />
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider className='mb-2 small' orientation="left" plain>
        Additional filters with Category & Product
      </Divider>
      <Row align="top">
        <Col span={24}>
          <Row>
            <Col md={24} className='d-flex py-2 justify-between'>
              <Flex>
                <div className='mb-2'>
                  <Select
                    style={{ width: 200 }}
                    value={selectedCategory}
                    onChange={onChangeCategoryFilter}
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder="Select Category"
                    size='small'
                  >
                    {orderStatusCounts?.categoryCounts?.map((category) => (
                      <Select.Option key={category.category_id} value={category.category_id}>
                        {`${category.category_name} (${category.count})`}
                      </Select.Option>
                    ))}
                  </Select>

                  {selectedCategory &&
                    <Select
                      style={{ width: 250 }}
                      onChange={onChangeProductFilter}
                      value={selectedProduct}
                      allowClear
                      showSearch
                      className='mx-2'
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="Select Product"
                      size='small'
                    >
                      {orderStatusCounts?.productCounts
                        ?.filter((product) => product?.product_category === tableParams.category_id)
                        ?.map((product) => (
                          <Select.Option key={product.product_id} value={product.product_id}>
                            {`${product.product_name} (${product.count})`}
                          </Select.Option>
                        ))}
                    </Select>
                  }
                </div>

                <Input.Search
                  size='small'
                  style={{ width: 250 }}
                  placeholder="Search by Product Name"
                  title='Search by Product Name'
                  allowClear
                  className={`${styles.search} mx-4`}
                  onChange={(e) => setProductNameFilter(e.target.value)}
                  value={productNameFilter}
                  onSearch={(value) => {
                    let productSelected = null;
                    let filterProductName = value;

                    if (value === "") {
                      productSelected = null;
                      filterProductName = null;
                    }

                    setSelectedProduct(productSelected);
                    setProductNameFilter(filterProductName);
                    setTableParams(prevTableParams => ({
                      ...prevTableParams,
                      product_id: productSelected,
                      product_name: filterProductName
                    }));
                  }}
                  onClear={() => {
                    let productSelected = null;
                    let filterProductName = null;

                    setSelectedProduct(productSelected);
                    setProductNameFilter(filterProductName);
                    setTableParams(prevTableParams => ({
                      ...prevTableParams,
                      product_id: productSelected,
                      product_name: filterProductName
                    }));
                  }}
                />
              </Flex>

              <Flex gap="small" wrap>
                <Icon
                  component={PiMicrosoftExcelLogoFill}
                  onClick={onExcelPrint}
                />

                <Icon
                  component={FaFileCsv}
                  onClick={onCsvPrint}
                />

                <Icon
                  component={FaFilePdf}
                  onClick={onPdfPrint}
                />
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="table-responsive pt-3">
        <SelectedOptions
          selectedRowKeys={selectedValues.selectedRowKeys}
          selectedRows={selectedValues.batchRecord}
          setIndex={setKey}
          handleHide={handleHide}
          rowsUnCheckHandler={rowsUnCheckHandler}
          baseUrl={baseUrl}
        />

        <Table
          components={components}
          columns={columns}
          rowKey='id'
          rowSelection={rowSelection}
          dataSource={orders}
          loading={{ spinning: loading, indicator: <Spin size="middle" indicator={LoadingIcon} /> }}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
            showQuickJumper: true,
            pageSizeOptions: [100, 200, 300, 400, 500],
            defaultPageSize: tableParams.pagination.pageSize,
            position: ["bottomRight"],
            ...tableParams.pagination
          }}
          className="ant-border-space"
          rowClassName={() => 'editable-row'}
          size="small"
          ellipsis
          responsive
          expandable={{
            expandedRowRender: (record) => <ExpandedRowRender record={record} />,
            rowExpandable: record => record.order_no !== 'Not Expandable',
          }}
          onChange={handleTableChange}
          scroll={{ y: `calc(100vh - 300px)` }}
        />
      </div>

      {visible &&
        <QuickEdit
          visible={visible}
          handleHide={handleHide}
          handleCancel={handleCancel}
          activeOrderId={activeOrderId}
          handleReset={handleReset}
          setKey={setKey}
          notificationTemplate={notificationTemplate}
          baseUrl={baseUrl}
          roles={roles}
          employees={employees}
          services={services}
          handleOpenDeviceChecker={showDeviceCheckerModal}
        />
      }

      {visibleDeviceChecker &&
        <DeviceChecker
          visible={visibleDeviceChecker}
          handleCancel={handleCancelDeviceChecker}
          activeOrderId={activeOrderId}
          setKey={setKey}
          baseUrl={baseUrl}
          services={services}
          maskClosable
        />
      }

      <Basket
        key='1'
        orders={orders}
        setKey={setKey}
        visible={visibleBulkAction}
        setVisible={setVisibleBulkAction}
        handleOpenDeviceChecker={showDeviceCheckerModal}
        baseUrl={baseUrl}
        ordersType="repair"
      />
    </div>
  );
}